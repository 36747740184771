export const roundNumber = (number: number, decimalPlaces?: number): number => {
    let integerPart = Math.trunc(number)
    let decimalPart = number - integerPart;

    const amountOfDecimalPlaces = decimalPlaces || 2;
    let zeroCount = 0;
    let roundedDecimalPart = 0;

    if (number % 1 !== 0) {
        // evaluate the amount of zeros after the comma before the first digit, e.g. 0.00000726 --> 5
        if (number < 0) {
            zeroCount = -Math.floor( Math.log10(decimalPart*-1) + 1);
        } else {
            zeroCount = -Math.floor( Math.log10(decimalPart) + 1);
        }
        zeroCount = Math.abs(zeroCount)
        roundedDecimalPart = Math.round(decimalPart * Math.pow(10, zeroCount+amountOfDecimalPlaces)) / Math.pow(10, zeroCount+amountOfDecimalPlaces)
    }

    let result = integerPart + roundedDecimalPart;

    // because of JavaScript floating point precision (e.g. 0.2 + 0.4 = 0.6000000000000001) the fraction digits need to be shortened in some cases
    if (roundedDecimalPart.toString().length > (result - Math.trunc(result))) {
        return parseFloat(result.toFixed(zeroCount+amountOfDecimalPlaces));
    } else {
        return result
    }
}
