export const formatNumericInput = (value: string | number | undefined, language: string, exponentialbreakpoint?: number): string => {
    if (value === 0) {
        return "0";
    }

    if(!value) {
        return "";
    }

    let temp = value;

    if (value < (exponentialbreakpoint ? exponentialbreakpoint : 0.001) && typeof temp === "number") {
        temp = temp.toExponential()
    }

    if (typeof temp === "number") {
        temp = temp.toString();
    }
    if (language === "en") {
        temp = temp.replace(",", ".")
    }
    if (language === "de") {
        temp = temp.replace(".", ",")
    }

    return temp;
}

export const formatChartOptions = (values: any) => {
    Object.keys(values).forEach(function(key){
        if (typeof values[key] === "string" && !key.includes("Unit") ) {
            values[key] = parseFloat(values[key])
        }
    });
    return values;
}
