import {Unit} from "../types";
import {unitConfig} from "../config/unitConfig";

export const convertTime = (value: number, desiredUnit: "s" | "min" | "h" | "d"): number => {
    // always converts from second
    let temp = value;
    if (desiredUnit === 'min') {
        temp = temp / 60;
    }

    if (desiredUnit === 'h') {
        temp = temp / 3600;
    }

    if (desiredUnit === 'd') {
        temp = temp / 86400;
    }

    return temp;
}

export const convertPressure = (value: number, desiredUnit: "mbar" | "Torr" | "hpa" | "Pa", inputUnit?: "mbar" | "Torr" | "hpa" | "Pa"): number => {
    let temp = value;

    if (inputUnit && inputUnit !== "mbar") {
        if (inputUnit === "Torr") temp = temp * 1.33322;
        if (inputUnit === "Pa") temp = temp * 0.01;
    }

    // always converts from mbar
    if (desiredUnit === 'Torr') {
        temp = temp * 0.750062;
    }

    if (desiredUnit === 'Pa') {
        temp = temp * 100;
    }

    return temp;
}

export const convertSuctionSpeed = (value: number, desiredUnit: "m3_h" | "l_s" ): number => {
    // always converts from m³/h
    let temp = value;
    if (desiredUnit === 'l_s') {
        temp = temp / 3.6;
    }

    return temp;
}

export const convertUnitToSi = (value: number, unitId: string) => {
    const givenUnit = unitConfig.find((unit: Unit) => unit.id === unitId)
    let result = value;

    if (givenUnit && value) {
        result = givenUnit.convertToSiUnit(value)
        result = parseFloat(result.toFixed(14));
    } else {
        return null;
    }

    return result
}

export const convertUnitFromSi = (value: number, unitId: string) => {
    const givenUnit = unitConfig.find((unit: Unit) => unit.id === unitId)
    let result = value;

    if (givenUnit) {
        if (givenUnit.convertFromSiUnit) {
            result = givenUnit.convertFromSiUnit(value)
        } else {
            result = value * (1 /givenUnit.convertToSiUnit(1))
        }
        result = parseFloat(result.toFixed(14));
    }

    return result
}
