import React from 'react';
import {Col, Form, Radio, Row, Select, Space} from "antd";
import _ from "lodash";
import NumberInput from "../NumberInput";
import {Unit} from "../../types";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {useTranslation} from "react-i18next";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    attributes: any[],
    selectedValue?: string,
    onChangeSelectedValue: (value: string) =>  void,
    onBackToHome: () => void,
}

const PressureIncreaseMethodForm = ({attributes, selectedValue, onChangeSelectedValue, onBackToHome}: Props) => {

    const [form] = Form.useForm();
    const {t} = useTranslation();

    let initialValues = {}

    attributes.forEach((attribute) => {
        Object.assign(initialValues, {
            [attribute.id]: null,
            [`${attribute.id}_unit`]: attribute.units[0].id
        })
    })

    const onSubmitForm = (values: any) => {
        const V = convertUnitToSi(parseLocalizedFloat(_.get(values, 'volume')),_.get(values, 'volume_unit'));
        console.log(V, 'VOLUME')
        const p1 = convertUnitToSi(parseLocalizedFloat(_.get(values, 'startingPressure')), _.get(values, 'startingPressure_unit'));
        console.log(p1, 'p1')
        const p2 = convertUnitToSi(parseLocalizedFloat(_.get(values, 'targetPressure')), _.get(values, 'targetPressure_unit'));
        console.log(p2, 'p2')
        const t = convertUnitToSi(parseLocalizedFloat(_.get(values, 'time')), _.get(values, 'time_unit'));
        console.log(t, 't')
        const q = convertUnitToSi(parseLocalizedFloat(_.get(values, 'leakRate')), _.get(values, 'leakRate_unit'));

        let result = null;

        if (selectedValue === "volume" && q && t && p1 && p2) {
            // V = (q * t) / (p2 - p1)
            result = (q * t) / (p2 - p1)
        } else if (selectedValue === "startingPressure" && q && t && p2 && V) {
            // p2 = V / (q * t) + p1
            result = p2 - (V / (q * t))
        } else if (selectedValue === "targetPressure" && q && t && V && p1) {
            // p1 = p2 - V / (q * t)
            result = (V / (q * t)) + p1
        } else if (selectedValue === "time" && q && V && p1 && p2) {
            // t = (V * (p2 - p1)) / q
            result = ((V * (p2 - p1)) / q)
        } else if (selectedValue === "leakRate" && V && t && p1 && p2) {
            result = (V * (p2 - p1)) / t
        }

        if (result) {
            // in gesuchte Einheit umrechnen
            const resultUnit = _.get(values, `${selectedValue}_unit`)
            result = convertUnitFromSi(result, resultUnit);
            result = roundNumber(result)
        }

        form.setFieldsValue({
            [`${selectedValue}`]: result
        })
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"full-width"}
                form={form}
                initialValues={initialValues}
                onFinish={onSubmitForm}
            >
                <Radio.Group
                    value={selectedValue}
                    onChange={(e) => {
                        const value = _.get(e, 'target.value')
                        form.setFieldsValue({
                            [value]: null
                        })
                        onChangeSelectedValue(value)
                    }}
                >
                    <Space className={"full-width"} direction="vertical">
                        {
                            attributes.map((attribute) => {
                                return <Row gutter={8} key={attribute.id}>
                                    <Col xs={24} md={8}>
                                        <Radio value={attribute.id}>{attribute.label}</Radio>
                                    </Col>
                                    <Col xs={24} md={16}>
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item name={attribute.id}>
                                                    <NumberInput disabled={selectedValue === attribute.id} exponentialbreakpoint={0.1} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}_unit`}>
                                                    <Select>
                                                        {
                                                            attribute.units.map((unit: Unit) => {
                                                                return <Select.Option key={unit.value} value={unit.id}>
                                                                    {unit.display}
                                                                </Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })
                        }
                    </Space>
                </Radio.Group>
                <SimpleCalculationActionButtons
                    onBack={onBackToHome}
                    onReset={() => form.resetFields()}
                    formSubmitOnCalculate={true}
                />
            </Form>
        </div>
    );
}

export default PressureIncreaseMethodForm;
