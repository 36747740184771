import React, {useState, useEffect} from "react";
import {Input, InputProps} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {formatNumericInput} from "../../utils/formatNumericInput";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";

interface NumberInputProps {
    value?: any;
    onChange?: (value: any) => void;
    integer?: boolean;
    exponentialbreakpoint?: number
}

export default function NumberInput(props: InputProps & NumberInputProps) {

    const {value, onChange, integer, exponentialbreakpoint} = props;
    const {i18n} = useTranslation();

    // the Input is handled as string --> if you need to get the number/float, use parseLocalizedFloat.ts
    const [inputValue, setInputValue] = useState(formatNumericInput(value, i18n.language, exponentialbreakpoint) || '');

    useEffect(() => {
        setInputValue(formatNumericInput(value, i18n.language, exponentialbreakpoint))
    }, [value]);

    let inputRegEx = /^[0-9]{1,10}([,|.]{1})?([0-9]{0,10})?([eE]{1})?[-]?([0-9]{0,2})?$/;

    if (integer) {
        inputRegEx = /^\d+$/
    }

    // test if the input value only contains regex characters
    const onValueChange = (e: any) => {
        const newValue = _.get(e, 'target.value', '');
        if (inputRegEx.test(newValue) || newValue === '') {
            setInputValue(newValue);
            if (typeof onChange === 'function') {
                onChange(newValue);
            }
        }
    };

    const onBlur = () => {
        let valueTemp = inputValue;
        if (inputValue.charAt(inputValue.length - 1) === '.' ||
            inputValue.charAt(inputValue.length - 1) === ',' ||
            inputValue.charAt(inputValue.length - 1) === 'e' ||
            inputValue.charAt(inputValue.length - 1) === 'E' ||
            inputValue.charAt(inputValue.length - 1) === '-')
        {
            valueTemp = inputValue.slice(0, -1);
            setInputValue(valueTemp);
        }
    };

    return (
        <Input
            {...props}
            value={inputValue}
            onChange={onValueChange}
            onBlur={onBlur}
        />
    )
}
