import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Grid, Row, Select} from "antd";
import _ from "lodash";
import {unitConfig} from "../../config/unitConfig";
import SimpleCalculationChamberCalculationForm from "../SimpleCalculationChamberCalculationForm";

interface Props {
    onBackToHome: () => void
}

const SimpleCalculationChamberCalculationFormWrapper = ({onBackToHome}: Props) => {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const chamberCalculationConfig = {
        headline: t('Calculation of chamber geometries'),
        geometrySelectValues: [
            {
                id: "cubic",
                label: t("Cubic chamber"),
                resultValues: [
                    {
                        id: "surfaceSize",
                        label: t('Calculated surface size'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    }
                ]
            },
            {
                id: "cylindrical",
                label: t("Cylindrical chamber"),
                resultValues: [
                    {
                        id: "completeSurfaceSize",
                        label: t('Surface size (complete)'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    },
                    {
                        id: "lateralSurfaceSize",
                        label: t('Lateral surface'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    },
                    {
                        id: "bottomCoverSurfaceSize",
                        label: t('Bottom / cover surface (single)'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    }
                ]
            },
            {
                id: "spherical",
                label: t("Spherical chamber"),
                resultValues: [
                    {
                        id: "surfaceSize",
                        label: t('Surface size'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    },
                    {
                        id: "crossSectionalArea",
                        label: t('Cross-sectional area'),
                        units: unitConfig.filter(unit => unit.category === "surface"),
                    },
                ]
            },
        ],
        attributes: [
            {
                id: "length",
                label: "Length",
                units: unitConfig.filter(unit => unit.category === "length"),
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "width",
                label: "Width",
                units: unitConfig.filter(unit => unit.category === "length"),
                type: "number",
                dependingOn: ["cubic"]
            },
            {
                id: "height",
                label: "Height",
                units: unitConfig.filter(unit => unit.category === "length"),
                type: "number",
                dependingOn: ["cubic", "cylindrical"]

            },
            {
                id: "diameter",
                label: "Diameter",
                units: unitConfig.filter(unit => unit.category === "length"),
                type: "number",
                dependingOn: ["cylindrical", "spherical"]
            },
            {
                id: "volume",
                label: "Volume",
                units: unitConfig.filter(unit => unit.category === "volume"),
                type: "number",
                dependingOn: ["cubic", "cylindrical", "spherical"],
            },
            {
                id: "surface",
                label: "Calculated surface size",
                units: unitConfig.filter(unit => unit.category === "surface"),
                type: "number"
            }
        ]
    }

    const [selectedGeometry, setSelectedGeomatry] = useState(_.get(_.head(chamberCalculationConfig.geometrySelectValues), 'id'))
    const [selectedValue, setSelectedValue] = useState(_.get(_.last(chamberCalculationConfig.attributes), 'id'))

    const filteredAttributes = chamberCalculationConfig.attributes.filter(attr => selectedGeometry && attr.dependingOn && attr.dependingOn.includes(selectedGeometry));
    const resultValues = _.get(chamberCalculationConfig.geometrySelectValues.find(x => x.id === selectedGeometry), 'resultValues');

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h2 className={'font-bold'}>{t('Vacuum Calculator')}</h2>
                    <h3>{t('Perform a simple vacuum calculation')}</h3>
                </div>
                <div className={'mt-32'}>
                    <h4 className={'font-bold mb-24'}>{chamberCalculationConfig.headline}</h4>
                    <Row gutter={screens.md ? 40 : 0}>
                        <Col span={screens.md ? 14 : 24}>
                            <Select
                                className={"full-width"}
                                value={selectedGeometry}
                                onChange={(value) => setSelectedGeomatry(value)}
                            >
                                {
                                    chamberCalculationConfig.geometrySelectValues.map((option) => {
                                        return <Select.Option value={option.id}>
                                            {option.label}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                            <SimpleCalculationChamberCalculationForm
                                attributes={filteredAttributes}
                                resultValues={resultValues}
                                geometry={selectedGeometry}
                                onBackToHome={onBackToHome}
                            />
                        </Col>
                        <Col span={screens.md ? 10 : 24}>

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default SimpleCalculationChamberCalculationFormWrapper;
