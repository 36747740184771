export const defaultDesorptionRate = 3e-7;
export const defaultLeakRate = 1e-7;
export const defaultDesorptionUnit = 'mbar_l_s_cm2';
export const defaultSizeUnit = 'm2';

export const defaultDesorptionAndLeakRates = [
    // pressure breakpoints in mbar
    {
        pressureBreakpoint: 1e-10,
        desorptionRate: 2e-11,
        leakRate: 1e-10
    },
    {
        pressureBreakpoint: 5e-10,
        desorptionRate: 2e-10,
        leakRate: 1e-8
    },
    {
        pressureBreakpoint: 1e-7,
        desorptionRate: 3e-8,
        leakRate: 1e-7
    },
    {
        pressureBreakpoint: 1e-6,
        desorptionRate: 3e-7,
        leakRate: 1e-7
    },
    {
        pressureBreakpoint: 1e-5,
        desorptionRate: 3e-7,
        leakRate: 1e-7
    }
]
