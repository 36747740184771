import React from 'react';
import {Col, Form, Row, Select} from "antd";
import _ from "lodash";
import NumberInput from "../NumberInput";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {formatNumericInput} from "../../utils/formatNumericInput";
import {useTranslation} from "react-i18next";
import "./index.css";
import {Button} from "@dreebit/pv-components";
import {Unit} from "../../types";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {roundNumber} from "../../utils/roundNumber";


interface Props {
    label: string,
    units: Unit[]
}

const SimpleCalculationUnitConversionForm = ({label, units}: Props) => {

    const {i18n} = useTranslation();
    const [form] = Form.useForm<{ inputValue: string; inputUnit: string; calculatedValue: string; calculatedUnit: string; }>();

    const calculatedValue = formatNumericInput((parseLocalizedFloat(Form.useWatch('inputValue', form)) * 2), i18n.language);

    const initialValues = {
        inputValue: null,
        inputUnit: _.get(_.first(units), 'id'),
        calculatedValue: null,
        calculatedUnit: _.get(units[1], 'id')
    }

    return (
        <div>
            <Form
                className={"unit-conversion-form"}
                form={form}
                initialValues={initialValues}
                layout={"vertical"}
                onValuesChange={(changedValues, allValues) => {
                    const inputValue = parseLocalizedFloat(_.get(allValues, 'inputValue'))
                    const inputUnit = _.get(allValues, 'inputUnit')
                    const calculatedUnit = _.get(allValues, 'calculatedUnit')

                    const valueToSi = convertUnitToSi(inputValue, inputUnit);
                    if (valueToSi) {
                        const calculatedValue = convertUnitFromSi(valueToSi, calculatedUnit)
                        form.setFieldsValue({
                            calculatedValue: formatNumericInput(roundNumber(calculatedValue), i18n.language)
                        })
                    }
                }}
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="inputValue" label={label} required={true}>
                            <NumberInput />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="inputUnit" label={" "} required={true}>
                            <Select>
                                {
                                    units.map((unit: Unit) => {
                                        return <Select.Option key={unit.id} value={unit.id}>
                                            {unit.display}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="calculatedValue" required={true}>
                            <NumberInput disabled={true} value={calculatedValue}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="calculatedUnit" required={true}>
                            <Select>
                                {
                                    units.map((unit: Unit) => {
                                        return <Select.Option key={unit.id} value={unit.id}>
                                            {unit.display}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className={"unit-conversion-form-button-wrapper"}>
                <Button type={"tertiary"} icon={"delete"} onClick={() => form.resetFields()} />
            </div>
        </div>
    );
}

export default SimpleCalculationUnitConversionForm;
