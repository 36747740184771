import React, {useEffect, useState} from 'react';
import useChamberStep from "../../hooks/useChamberStep";
import {Checkbox, Form, FormInstance, Select} from "antd";
import {useTranslation} from "react-i18next";
import DesorptionSlider from "../DesorptionSlider";
import LeakRateSvg from "../Svg/LeakRateSvg";
import {getValidationStatus} from "../../utils/getValidationStatus";
import {InfoCircleOutlined} from "@ant-design/icons";
import NumberInput from "../NumberInput";
import _ from "lodash";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {generateSliderConfig} from "../../utils/generateSliderConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void
    initialValues: {[key: string]: any}
    fieldValidations: any[]
    standardLeakRate: number
}

export default function ChamberLeakRateContent({form, stepId, onSubmit, initialValues, fieldValidations, standardLeakRate}: Props) {

    const step = useChamberStep(stepConfig, stepId);
    const {t} = useTranslation();

    const defaultLeakRateUnit = _.get(initialValues, 'leakRateUnit')

    const [leakRateUnit, setLeakRateUnit] = useState(initialValues.leakRateUnit || defaultLeakRateUnit)

    const sliderConfig = generateSliderConfig(leakRateUnit)

    const [leakRate, setLeakRate] = useState(Number(initialValues.leakRate) || standardLeakRate)

    const { Option } = Select;

    const debounced = _.debounce((value: any) => {
        let tmp = parseLocalizedFloat(value);
        const min = sliderConfig.minExponential;
        const max = sliderConfig.maxExponential;

        if (value && parseLocalizedFloat(value) < min) {
            tmp = min
        } else if (value && parseLocalizedFloat(value) > max) {
            tmp = max
        }

        if (value && parseLocalizedFloat(value)) {
            setLeakRate(tmp);
        }
    }, 2000);

    return (
        <div>
            <Form
                id={`form-${stepId}`}
                form={form}
                layout={'vertical'}
                onFinish={(values) => {
                    const data = {
                        leakRate: {
                            value: parseFloat(leakRate.toString()),
                            unit: leakRateUnit
                        },
                        submitType: _.get(values, 'submitType', null)
                    };
                    onSubmit(data, true);
                }}
            >
                <SubmitTypeFormItem />
                <h3 className={'font-bold'}>{t(step.title || "")}</h3>
                <p className={"mv-24"}>{t(step.subtitle || "")}</p>
                <div style={{paddingLeft: '6px', paddingRight: '6px'}}>
                    <LeakRateSvg/>
                </div>
                <DesorptionSlider
                    value={leakRate}
                    onChange={(newLeakRate: any) => {
                        setLeakRate(newLeakRate.toExponential(0));
                    }}
                    config={sliderConfig}
                />
                <Form.Item
                    className={'pt-24'}
                    label={t(step.attributes[0].label)}
                    validateStatus={getValidationStatus(fieldValidations, 'leakRate')}
                >
                    <NumberInput
                        value={leakRate}
                        onChange={debounced}
                        addonAfter={
                            <Select
                                value={leakRateUnit}
                                onChange={val => setLeakRateUnit(val)}
                                tabIndex={-1}
                            >
                                {step.attributes[0].units?.map((unit, index) => {
                                    return <Option value={unit.value} key={index}>{unit.display}</Option>
                                })}
                            </Select>
                        }
                    />
                </Form.Item>
            </Form>
            <div>
                <InfoCircleOutlined rev={"default"} style={{color: '#DD1541'}}/>
                <span className={'font-bold pl-8'}>{t('leakRate_note')}</span>
            </div>
        </div>
    )
}
