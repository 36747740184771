import React, {useEffect, useState} from 'react';
import {Col, Form, Radio, Row, Select, Space} from "antd";
import _ from "lodash";
import "./index.css";
import NumberInput from "../NumberInput";
import {Unit} from "../../types";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {useTranslation} from "react-i18next";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {unitConfig} from "../../config/unitConfig";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    attributes: any[]
    resultValues?: any[]
    geometry?: string
    onBackToHome: () => void
}

const SimpleCalculationChamberCalculationForm = ({attributes, resultValues, geometry, onBackToHome}: Props) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [selectedCalculationValue, setSelectedCalculationValue] = useState(_.get(_.last(attributes), 'id'))
    const initialResultState = resultValues?.reduce((acc, curr) => (acc[curr.id]= {
        value: undefined,
        unit: _.get(_.head(curr.units), 'factor')
    }, acc), {})
    const [result, setResult] = useState(initialResultState || {})

    let initialValues = {}
    attributes.forEach((attribute) => {
        Object.assign(initialValues, {
            [attribute.id]: null,
            [`${attribute.id}_unit`]: attribute.units[0].id
        })
    })
    initialValues = {
        ...initialValues,
        diameter: null,
        diameter_unit: _.get(_.head(unitConfig.filter(unit => unit.category === "length")), 'id')
    }

    useEffect(() => {
        attributes.forEach((attribute) => {
            Object.assign(initialValues, {
                [attribute.id]: null,
                [`${attribute.id}_unit`]: attribute.units[0].id
            })
        })
    }, [geometry, attributes])

    useEffect(() => {
        const initialResultState = resultValues?.reduce((acc, curr) => (acc[curr.id]= {
            value: undefined,
            unit: _.get(_.head(curr.units), 'id')
        }, acc), {})

        setResult(initialResultState)
    }, [resultValues])

    const onSubmitForm = (values: any) => {
        let calculatedResult = null;

        if (geometry === "cubic") {
            let length = convertUnitToSi(parseLocalizedFloat(_.get(values, 'length')), _.get(values, 'length_unit'));
            let width = convertUnitToSi(parseLocalizedFloat(_.get(values, 'width')), _.get(values, 'width_unit'));
            let height = convertUnitToSi(parseLocalizedFloat(_.get(values, 'height')), _.get(values, 'height_unit'));
            let volume = convertUnitToSi(parseLocalizedFloat(_.get(values, 'volume')), _.get(values, 'volume_unit'));

            if (selectedCalculationValue === "length" && width && height && volume) {
                length = volume / (height * width) *  (1 / _.get(values, 'length_unit'))
                length = convertUnitFromSi(length, _.get(values, 'length_unit'))
                form.setFieldsValue({
                    [`length`]: roundNumber(length)
                })
            }
            if (selectedCalculationValue === "width" && length && height && volume) {
                width = volume / (height * length)
                width = convertUnitFromSi(width, _.get(values, 'width_unit'))
                form.setFieldsValue({
                    [`width`]: roundNumber(width)
                })
            }
            if (selectedCalculationValue === "height" && length && width && volume) {
                height = volume / (length * width)
                height = convertUnitFromSi(height, _.get(values, 'height_unit'))
                form.setFieldsValue({
                    [`height`]: roundNumber(height)
                })
            }
            if (selectedCalculationValue === "volume" && length && width && height) {
                volume = (length * height * width)
                volume = convertUnitFromSi(volume, _.get(values, 'volume_unit'))
                form.setFieldsValue({
                    [`volume`]: roundNumber(volume)
                })
            }

            if (width && length && height) {
                let cubicSurfaceSize = ((2 * width * length) + (2 * height * length) + (2 * width * height));
                cubicSurfaceSize = convertUnitFromSi(cubicSurfaceSize, _.get(result["surfaceSize"], 'unit'))

                calculatedResult = {
                    ["surfaceSize"]: {
                        ...result["surfaceSize"],
                        value: roundNumber(cubicSurfaceSize)
                    }
                }
            }
        }

        if (geometry === "cylindrical") {
            let height = convertUnitToSi(parseLocalizedFloat(_.get(values, 'height')), _.get(values, 'height_unit'));
            let diameter = convertUnitToSi(parseLocalizedFloat(_.get(values, 'diameter')), _.get(values, 'diameter_unit'));
            let volume = convertUnitToSi(parseLocalizedFloat(_.get(values, 'volume')), _.get(values, 'volume_unit'));

            if (selectedCalculationValue === "height" && volume && diameter) {
                let radius = diameter / 2;

                height = (volume / Math.PI * Math.pow(radius, 2))
                height = convertUnitFromSi(height, _.get(values, 'height_unit'))
                form.setFieldsValue({
                    [`height`]: roundNumber(height)
                })
            }
            if (selectedCalculationValue === "diameter" && volume && height) {
                diameter = Math.sqrt((volume)/(Math.PI*height)) * 2
                diameter = convertUnitFromSi(diameter, _.get(values, 'diameter_unit'))
                form.setFieldsValue({
                    [`diameter`]: roundNumber(diameter)
                })
            }
            if (selectedCalculationValue === "volume" && diameter && height) {
                let radius = diameter / 2;

                volume = Math.PI * Math.pow(radius, 2) *  height
                volume = convertUnitFromSi(volume, _.get(values, 'volume_unit'))
                form.setFieldsValue({
                    [`volume`]: roundNumber(volume)
                })
            }

            if (diameter && height) {
                let radius = diameter / 2;

                let lateralSurfaceSize = 2 * Math.PI * radius * height;
                lateralSurfaceSize = convertUnitFromSi(lateralSurfaceSize, _.get(result["lateralSurfaceSize"], 'unit'))
                let bottomCoverSurfaceSize = Math.PI * Math.pow(radius, 2);
                bottomCoverSurfaceSize = convertUnitFromSi(bottomCoverSurfaceSize, _.get(result["bottomCoverSurfaceSize"], 'unit'))
                let completeSurfaceSize = 2 * bottomCoverSurfaceSize + lateralSurfaceSize;
                completeSurfaceSize = convertUnitFromSi(completeSurfaceSize, _.get(result["completeSurfaceSize"], 'unit'))

                calculatedResult = {
                    ["completeSurfaceSize"]: {
                        ...result["completeSurfaceSize"],
                        value: roundNumber(completeSurfaceSize)
                    },
                    ["lateralSurfaceSize"]: {
                        ...result["lateralSurfaceSize"],
                        value: roundNumber(lateralSurfaceSize)
                    },
                    ["bottomCoverSurfaceSize"]: {
                        ...result["bottomCoverSurfaceSize"],
                        value: roundNumber(bottomCoverSurfaceSize)
                    }
                }
            }
        }

        if (geometry === "spherical") {
            let diameter = convertUnitToSi(parseLocalizedFloat(_.get(values, 'diameter')), _.get(values, 'diameter_unit'));
            let volume = convertUnitToSi(parseLocalizedFloat(_.get(values, 'volume')), _.get(values, 'volume_unit'));

            if (selectedCalculationValue === "diameter" && volume) {
                diameter = Math.cbrt((volume*3)/(Math.PI*4)) * 2
                diameter = convertUnitFromSi(diameter, _.get(values, 'diameter_unit'))
                form.setFieldsValue({
                    [`diameter`]: roundNumber(diameter)
                })
            }
            if (selectedCalculationValue === "volume" && diameter) {
                volume = 4/3 * Math.PI * Math.pow((diameter/2), 3)
                volume = convertUnitFromSi(volume, _.get(values, 'volume_unit'))
                form.setFieldsValue({
                    [`volume`]: roundNumber(volume)
                })
            }

            if (diameter) {
                let radius = diameter / 2;
                let surfaceSize = (4 * Math.PI * Math.pow(diameter, 2));
                surfaceSize = convertUnitFromSi(surfaceSize, _.get(result["surfaceSize"], 'unit'))
                let crossSectionalArea = (Math.PI * Math.pow(radius, 2));
                crossSectionalArea = convertUnitFromSi(crossSectionalArea, _.get(result["crossSectionalArea"], 'unit'))

                calculatedResult = {
                    ["surfaceSize"]: {
                        ...result["surfaceSize"],
                        value: roundNumber(surfaceSize)
                    },
                    ["crossSectionalArea"]: {
                        ...result["crossSectionalArea"],
                        value: roundNumber(crossSectionalArea)
                    }
                }
            }
        }

        setResult(calculatedResult);
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"full-width pt-24"}
                form={form}
                initialValues={initialValues}
                onFinish={onSubmitForm}
            >
                <Radio.Group
                    value={selectedCalculationValue}
                    onChange={(e) => {
                        const value = _.get(e, 'target.value')
                        form.setFieldsValue({
                            [value]: null
                        })
                        setSelectedCalculationValue(value)
                    }}
                >
                    <Space className={"full-width form-border-top form-border-bottom pt-24"} direction="vertical">
                        {
                            attributes.map((attribute) => {
                                return <Row gutter={8} key={attribute.id}>
                                    <Col xs={24} md={8}>
                                        <Radio value={attribute.id}>{t(attribute.label)}</Radio>
                                    </Col>
                                    <Col xs={24} md={16}>
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item name={attribute.id}>
                                                    <NumberInput
                                                        disabled={selectedCalculationValue === attribute.id}
                                                        exponentialbreakpoint={0.1}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}_unit`}>
                                                    <Select>
                                                        {
                                                            attribute.units.map((unit: Unit) => {
                                                                return <Select.Option key={unit.value} value={unit.id}>
                                                                    {unit.display}
                                                                </Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })
                        }
                    </Space>
                </Radio.Group>
                {
                    resultValues && resultValues.length > 0 ?
                        <div>
                            {
                                resultValues.map((val) => {
                                    return <div className={"result-row"} key={val.id}>
                                        <Row gutter={16} align={"middle"}>
                                            <Col xs={24} md={12}>
                                                <span className={"font-bold"}>{val.label}:</span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <NumberInput disabled value={_.get(result[val.id], 'value', undefined)} />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Select
                                                    className={"full-width"}
                                                    value={_.get(result[val.id], 'unit', undefined)}
                                                    onChange={(value => setResult({
                                                        ...result,
                                                        [val.id]: {
                                                            ...result[val.id],
                                                            unit: value
                                                        }
                                                    }))}
                                                >
                                                    {
                                                        val.units.map((unit: Unit) => {
                                                            return <Select.Option value={unit.id} key={unit.value}>
                                                                {unit.display}
                                                            </Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                    </div>
                                })
                            }
                        </div>
                    : null
                }

                <SimpleCalculationActionButtons
                    onBack={onBackToHome}
                    onReset={() => form.resetFields()}
                    formSubmitOnCalculate={true}
                />
            </Form>
        </div>
    );
}

export default SimpleCalculationChamberCalculationForm;
