import {Unit} from "../types";

export const unitConfig: Unit[] = [
    {
        id: "pressure_hpa",
        display: "hPa",
        value: "hpa",
        isSiUnit: false,
        category: "pressure",
        factor: 100,
        convertToSiUnit: (x: number) => {
            return x * 100;
        }
    },
    {
        id: "pressure_mbar",
        display: "mbar",
        value: "mbar",
        isSiUnit: false,
        category: "pressure",
        factor: 100,
        convertToSiUnit: (x: number) => {
            return x * 100;
        }
    },
    {
        id: "pressure_Pa",
        display: "Pa",
        value: "Pa",
        isSiUnit: true,
        category: "pressure",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "pressure_Torr",
        display: "Torr",
        value: "Torr",
        isSiUnit: false,
        category: "pressure",
        factor: 133.322,
        convertToSiUnit: (x: number) => {
            return x * 133.322;
        }
    },
    {
        id: "time_s",
        display: "s",
        value: "s",
        isSiUnit: true,
        category: "time",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "time_min",
        display: "min",
        value: "min",
        isSiUnit: false,
        category: "time",
        factor: 60,
        convertToSiUnit: (x: number) => {
            return x * 60;
        }
    },
    {
        id: "time_h",
        display: "h",
        value: "h",
        isSiUnit: false,
        category: "time",
        factor: 3600,
        convertToSiUnit: (x: number) => {
            return x * 3600;
        }
    },
    {
        id: "time_d",
        display: "d",
        value: "d",
        isSiUnit: false,
        category: "time",
        factor: 86400,
        convertToSiUnit: (x: number) => {
            return x * 86400;
        }
    },
    {
        id: "length_m",
        display: "m",
        value: "m",
        isSiUnit: true,
        category: "length",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "length_mm",
        display: "mm",
        value: "mm",
        isSiUnit: false,
        category: "length",
        factor: 0.001,
        convertToSiUnit: (x: number) => {
            return x * 0.001;
        }
    },
    {
        id: "length_cm",
        display: "cm",
        value: "cm",
        isSiUnit: false,
        category: "length",
        factor: 0.01,
        convertToSiUnit: (x: number) => {
            return x * 0.01;
        }
    },
    {
        id: "length_in",
        display: "in",
        value: "in",
        isSiUnit: false,
        category: "length",
        factor: 0.0254,
        convertToSiUnit: (x: number) => {
            return x * 0.0254;
        }
    },
    {
        id: "surface_m2",
        display: "m²",
        value: "m2",
        isSiUnit: true,
        category: "surface",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "surface_cm2",
        display: "cm²",
        value: "cm2",
        isSiUnit: true,
        category: "surface",
        factor: 0.0001,
        convertToSiUnit: (x: number) => {
            return x * 0.0001;
        }
    },
    {
        id: "surface_mm2",
        display: "mm²",
        value: "mm2",
        isSiUnit: false,
        category: "surface",
        factor: 1e-6,
        convertToSiUnit: (x: number) => {
            return x * 1e-6;
        }
    },
    {
        id: "surface_in2",
        display: "in²",
        value: "in2",
        isSiUnit: false,
        category: "surface",
        factor: 0.00064516,
        convertToSiUnit: (x: number) => {
            return x * 0.00064516;
        }
    },
    {
        id: "volume_m3",
        display: "m³",
        value: "m3",
        isSiUnit: true,
        category: "volume",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "volume_l",
        display: "l",
        value: "l",
        isSiUnit: false,
        category: "volume",
        factor: 0.001,
        convertToSiUnit: (x: number) => {
            return x * 0.001;
        }
    },
    {
        id: "volume_cm3",
        display: "cm³",
        value: "cm3",
        isSiUnit: false,
        category: "volume",
        factor: 1e-6,
        convertToSiUnit: (x: number) => {
            return x * 1e-6;
        }
    },
    {
        id: "volume_mm3",
        display: "mm³",
        value: "mm3",
        isSiUnit: false,
        category: "volume",
        factor: 1e-9,
        convertToSiUnit: (x: number) => {
            return x * 1e-9;
        }
    },
    {
        id: "desorptionRate_mbar_l_s_cm2",
        display: "mbar*l/(s*cm²)",
        value: "mbar_l_s_cm2",
        isSiUnit: false,
        category: "desorptionRate",
        factor: 1000,
        convertToSiUnit: (x: number) => {
            return x * 1000;
        }

    },
    {
        id: "desorptionRate_pa_m3_s_m2",
        display: "Pa*m³/(s*m²)",
        value: "pa_m3_s_m2",
        isSiUnit: true,
        category: "desorptionRate",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "desorptionRate_torr_l_s_cm2",
        display: "Torr*l/(s*cm²)",
        value: "torr_l_s_cm2",
        isSiUnit: false,
        category: "desorptionRate",
        factor: 4000/3,
        convertToSiUnit: (x: number) => {
            return x * 4000/3;
        }
    },
    {
        id: "leakRate_mbar_l_s",
        display: "mbar*l/s",
        value: "mbar_l_s",
        isSiUnit: false,
        category: "leakRate",
        factor: 0.1,
        convertToSiUnit: (x: number) => {
            return x * 0.1;
        }
    },
    {
        id: "leakRate_pa_m3_s",
        display: "Pa*m³/s",
        value: "pa_m3_s",
        isSiUnit: true,
        category: "leakRate",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "suctionSpeed_m3_h",
        display: "m³/h",
        value: "m3_h",
        isSiUnit: false,
        category: "suctionSpeed",
        factor: 1/3600,
        convertToSiUnit: (x: number) => {
            return (x / 3600);
        }
    },
    {
        id: "suctionSpeed_l_s",
        display: "l/s",
        value: "l_s",
        isSiUnit: false,
        category: "suctionSpeed",
        factor: 0.001,
        convertToSiUnit: (x: number) => {
            return x * 0.001;
        }
    },
    {
        id: "gasFlow_mbar_l_s",
        display: "mbar*l/s",
        value: "mbar_l_s",
        isSiUnit: false,
        category: "gasFlow",
        factor: 0.1,
        convertToSiUnit: (x: number) => {
            return x * 0.1;
        }
    },
    {
        id: "gasFlow_sccm",
        display: "sccm",
        value: "sccm",
        isSiUnit: false,
        category: "gasFlow",
        factor: 0.00169,
        convertToSiUnit: (x: number) => {
            return x * 0.00169;
        }
    },
    {
        id: "temperature_K",
        display: "K",
        value: "K",
        isSiUnit: true,
        category: "temperature",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x;
        }
    },
    {
        id: "temperature_degF",
        display: "°F",
        value: "degF",
        isSiUnit: false,
        category: "temperature",
        //TODO: other solution for temperature conversion --> (0 K − 273,15) × 9/5 + 32 = -459,7 °F
        factor: undefined,
        convertToSiUnit: (x: number) => {
            return ((x - 32) * 5/9 + 273.15)
        },
        convertFromSiUnit: (x: number) => {
            return ((x - 273.15) * 9/5 + 32)
        }
    },
    {
        id: "temperature_degC",
        display: "°C",
        value: "degC",
        isSiUnit: false,
        category: "temperature",
        //TODO: other solution for temperature conversion --> 0 K − 273,15 = -273,1 °C
        factor: undefined,
        convertToSiUnit: (x: number) => {
            return x + 273.15
        },
        convertFromSiUnit: (x: number) => {
            return x - 273.15
        }
    },
    {
        id: "massFlow_g_a",
        display: "g/a",
        value: "g_a",
        isSiUnit: false,
        category: "massFlow",
        factor: 3.1709792e-11,
        convertToSiUnit: (x: number) => {
            return x * 0.001 / 31536000
        }
    },
    {
        id: "massFlow_kg_s",
        display: "kg/s",
        value: "kg_s",
        isSiUnit: true,
        category: "massFlow",
        factor: 1,
        convertToSiUnit: (x: number) => {
            return x
        }
    }
]

