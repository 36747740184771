import React from 'react';
import { MathComponent } from "mathjax-react";
import "./index.css"

interface Props {
    rawString?: string
    display?:boolean
}

const MathFormula = ({rawString, display}: Props) => {
    if (rawString) {
        return <MathComponent tex={rawString} display={display}/>;
    }
    return null;
}

export default MathFormula;
