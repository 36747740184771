import {Gas} from "../types";

export const gasConfig: Gas[] = [
    {
        id: "air",
        label: "Air",
        chemicalFormula: undefined,
        //MolarMass in kg/mol
        molarMass: 0.028,
        //Viscosity in Pa*s
        viscosity: 0.0000172,
    },
    {
        id: "nitrogen",
        label: "Nitrogen",
        chemicalFormula: "N2",
        //MolarMass in kg/mol
        molarMass: 0.028,
        //Viscosity in Pa*s
        viscosity: 0.0000166,
    },
    {
        id: "argon",
        label: "Argon",
        chemicalFormula: "Ar",
        //MolarMass in kg/mol
        molarMass: 0.04,
        //Viscosity in Pa*s
        viscosity: 0.000021,
    },
    {
        id: "helium",
        label: "Helium",
        chemicalFormula: "He",
        //MolarMass in kg/mol
        molarMass: 0.004,
        //Viscosity in Pa*s
        viscosity: 0.0000189,
    },
    {
        id: "hydrogen",
        label: "Hydrogen",
        chemicalFormula: "H2",
        //MolarMass in kg/mol
        molarMass: 0.002,
        //Viscosity in Pa*s
        viscosity: 0.00000841,
    },
]
