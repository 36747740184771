import React from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import _ from "lodash";
import {mobileContent} from "../../../config/mobileContent";
import SavedCalculationsMobileModal from "../../../components/MobileModals/savedCalculations";
import SavedSurfacesMobileModal from "../../../components/MobileModals/savedSurfaces";
import ContactForm from "../../../components/ContactForm";
import TargetPressureWarningMobileModal from "../../../components/MobileModals/targetPressureWarning";
import {useTranslation} from "react-i18next";

const Components: {[key: string]: any} = {
    "savedCalculations": SavedCalculationsMobileModal,
    "savedSurfaces": SavedSurfacesMobileModal,
    "contactForm": ContactForm,
    "targetPressureWarning": TargetPressureWarningMobileModal
}

export default function MobileContentRoute() {

    const {content} = useParams<any>();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const calculationType = "chamberEvacuation";

    const closeMobileContent = () => {
        let pathName = _.get(location, 'pathname')
        let pathNameParts = pathName.split('/mobile/')
        const route = pathNameParts[0]

        history.push(route);
    };

    const title = t(mobileContent[content])
    let component = null

    if (typeof Components[content] !== "undefined") {
        component = React.createElement(Components[content], {
            calculationType: calculationType,
            closeModal: () => closeMobileContent()
        });
    }

    return <div className={'flex flex-col'}>
        <div className={'flex flex-row flex-space-between pb-16'}>
            <h5>{title}</h5>
            <CloseOutlined
                onClick={closeMobileContent}
                rev={"default"}
            />
        </div>
        {component}
    </div>
}
