import React, {useState} from 'react';
import "./index.css";
import {Col, Form, Row, Select} from "antd";
import {unitConfig} from "../../config/unitConfig";
import {useTranslation} from "react-i18next";
import NumberInput from "../NumberInput";
import {getFormAttributes} from "./getFormAttributes";
import {Unit} from "../../types";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import _ from "lodash";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    onBackToHome: () => void
}

interface ResultType {
    value: number | undefined,
    unit: string | undefined
}

const EffectiveSuctionSpeedForm = ({onBackToHome}: Props) => {

    const calculationName = "effectiveSuctionSpeed"
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const resultUnits = unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed");
    const [result, setResult] = useState<ResultType>({value: undefined, unit: resultUnits[0].id})
    const [calculationDone, setCalculationDone] = useState(false);

    const attributes = getFormAttributes(calculationName, t);
    let initialValues = {}

    attributes.forEach((attribute) => {
        if (attribute.units && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: null,
                [`${attribute.id}_unit`]: attribute.units[0].id
            })
        } else if (attribute.options && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: attribute.options[0].id
            })
        }
    })

    const calculateResult = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                // S = (S * L) / (S + L)
                const S = convertUnitToSi(parseLocalizedFloat(_.get(values, 'suctionSpeed')), _.get(values, 'suctionSpeed_unit'));
                const L = convertUnitToSi(parseLocalizedFloat(_.get(values, 'conductance')), _.get(values, 'conductance_unit'));

                if (S && L) {
                    let calculatedResult = (S * L) / (S + L);
                    if (result.unit) {
                        // convert result to wanted unit
                        calculatedResult = convertUnitFromSi(calculatedResult, result.unit)
                        calculatedResult = roundNumber(calculatedResult)
                    }
                    setResult({...result, value: calculatedResult})
                    setCalculationDone(true)
                }
            });
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"form-border-bottom"}
                layout={"vertical"}
                form={form}
                initialValues={initialValues}
            >
                <Row gutter={16}>
                    {
                        attributes.map(attribute => {
                            return <Col
                                key={attribute.id}
                                xs={{span: attribute.width.xs}}
                                md={{span: attribute.width.md}}
                            >
                                {
                                    attribute.units && attribute.type === "number" ?
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}`} label={attribute.label} required={true} rules={[{ required: true }]}>
                                                    <NumberInput />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}_unit`} label={" "} required={true}>
                                                    <Select tabIndex={-1}>
                                                        {attribute.units.map((unit: Unit) => {
                                                            return <Select.Option
                                                                value={unit.id}
                                                                key={unit.value}
                                                            >
                                                                {unit.display}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    : null
                                }
                            </Col>
                        })
                    }
                </Row>
            </Form>
            <div className={"result-row"}>
                <Row gutter={16} align={"middle"}>
                    <Col xs={24} md={12}>
                        <span className={"font-bold"}>{t("Calculated effective suction speed")}:</span>
                    </Col>
                    <Col xs={12} md={6}>
                        <NumberInput className={calculationDone ? "success-border" : ""} disabled value={result.value} exponentialbreakpoint={0.01}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Select
                            className={"full-width"}
                            value={result.unit}
                            onChange={(value => setResult({...result, unit: value}))}
                        >
                            {
                                resultUnits.map((unit: Unit) => {
                                    return <Select.Option value={unit.id} key={unit.value}>
                                        {unit.display}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
            </div>
            <SimpleCalculationActionButtons
                onBack={onBackToHome}
                onReset={() => {
                    setCalculationDone(false)
                    setResult({...result, value: undefined})
                    form.resetFields()
                }}
                onCalculate={() => calculateResult()}
            />
        </div>
    );
}

export default EffectiveSuctionSpeedForm;

