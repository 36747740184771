import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Form, Grid, Row, Select} from "antd";
import _ from "lodash";
import MathFormula from "../MathFormula";
import PressureIncreaseMethodForm from "../SimpleCalculationLeakDetectionForm/pressureIncreaseMethod";
import {unitConfig} from "../../config/unitConfig";
import {Unit} from "../../types";
import LeakRateConversionForm from "../SimpleCalculationLeakDetectionForm/leakRateConversion";

interface Props {
    onBackToHome: () => void
}

const SimpleCalculationFormWrapper = ({onBackToHome}: Props) => {

    const {t, i18n} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const leakDetectionCalculationConfig = {
        headline: t('Leak detection calculations'),
        calculationTypes: [
            {
                id: "pressureIncreaseMethod",
                title: t('Pressure increase method'),
                formulaLegend: [
                    String.raw`q(pV)...${t('Leak rate')}`,
                    String.raw`V...${t('Volume')}`,
                    String.raw`p(1)...${t('Target pressure')}`,
                    String.raw`p(2)...${t('Starting pressure')}`,
                    String.raw`t...${t('Time')}`
                ],
                attributes: [
                    {
                        id: "volume",
                        label: t("Volume"),
                        units: unitConfig.filter((unit: Unit) => unit.category === "volume"),
                        formula: String.raw`V = \frac{q(pV) \cdot t}{(p(2)) - p(1))}`
                        // V = (q * t) / (p2 - p1)
                    },
                    {
                        id: "startingPressure",
                        label: t("Starting pressure"),
                        units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                        formula: String.raw`p(2) = \frac{V}{q(pV) \cdot t} + p(1)`
                        // p1 = p2 - V / (q * t)
                    },
                    {
                        id: "targetPressure",
                        label: t("Target pressure"),
                        units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                        formula: String.raw`p(1) = p(2) - \frac{V}{q(pV) \cdot t}`
                        // p2 = V / (q * t) + p1
                    },
                    {
                        id: "time",
                        label: t("Time"),
                        units: unitConfig.filter((unit: Unit) => unit.category === "time"),
                        formula: String.raw`t = \frac{V \cdot (p(2)) - p(1))}{q(pV)}`
                        // t = (V * (p2 - p1)) / q
                    },
                    {
                        id: "leakRate",
                        label: t("Leak rate"),
                        units: unitConfig.filter((unit: Unit) => unit.category === "leakRate"),
                        formula: String.raw`q(pV) = \frac{V \cdot (p(2)) - p(1))}{t}`
                        // q = (V * (p2 - p1)) / t
                    },
                ]
            },
            {
                id: "leakRateConversion",
                title: t('Leak rate conversion'),
                formula: String.raw`q(pV) = q(m) \cdot \frac{R \cdot T}{M}`,
                formulaAddition: String.raw`q(pV, Helium) = q(pV) \cdot \frac{\eta}{\eta (Helium)}`,
                formulaLegend: [
                    String.raw`q(pV)...${t('Leak rate')}`,
                    (i18n.language === 'de' ? String.raw`q(m)...Massenstrom` : String.raw`R...Mass \: flow`),
                    (i18n.language === 'de' ? String.raw`R...Gaskonstante` : String.raw`R...Gas \: constant`),
                    String.raw`T...${t('Temperature')}`,
                    (i18n.language === 'de' ? String.raw`M...Molekulare \: Masse \: (Gas)` : String.raw`M...Molecular \: mass \: (gas)`),
                ],
            }
        ],
    }

    const pressureIncreaseMethodConfig = leakDetectionCalculationConfig.calculationTypes.find(x => x.id === "pressureIncreaseMethod");
    const leakRateConversionConfig = leakDetectionCalculationConfig.calculationTypes.find(x => x.id === "leakRateConversion");

    const [calculationType, setCalculationType] = useState(_.get(_.first(leakDetectionCalculationConfig.calculationTypes), 'id'));
    const [selectedValue, setSelectedValue] = useState(_.get(_.last(pressureIncreaseMethodConfig?.attributes), 'id'))

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h2 className={'font-bold'}>{t('Vacuum Calculator')}</h2>
                    <h3>{t('Perform a simple vacuum calculation')}</h3>
                </div>
                <div className={'mt-32'}>
                    <h4 className={'font-bold mb-24'}>{t('Leak detection calculations')}</h4>
                    <Row gutter={screens.md ? 40 : 0}>
                        <Col span={screens.md ? 14 : 24}>
                            <Form layout={'vertical'}>
                                <Form.Item label={t("What do you want to calculate?")} required={true}>
                                    <Select value={calculationType} onChange={val => setCalculationType(val)}>
                                        {
                                            leakDetectionCalculationConfig.calculationTypes.map(o => {
                                                return <Select.Option value={o.id} key={o.id}>
                                                    {o.title}
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>

                            {
                                calculationType === "pressureIncreaseMethod" ?
                                    <PressureIncreaseMethodForm
                                        attributes={pressureIncreaseMethodConfig?.attributes || []}
                                        selectedValue={selectedValue}
                                        onChangeSelectedValue={(value) => setSelectedValue(value)}
                                        onBackToHome={onBackToHome}
                                    />
                                : calculationType === "leakRateConversion" ?
                                    <LeakRateConversionForm onBackToHome={onBackToHome}/>
                                : null
                            }
                        </Col>
                        <Col span={screens.md ? 10 : 24}>
                            {
                                screens.md && calculationType === "pressureIncreaseMethod" ?
                                    <div className={"pl-104"}>
                                        <MathFormula rawString={_.get(_.find(pressureIncreaseMethodConfig?.attributes, (attr) => attr.id === selectedValue), 'formula')} />
                                        <div className={"mt-48"}>
                                            {
                                                pressureIncreaseMethodConfig?.formulaLegend.map((formula) => {
                                                    return <MathFormula rawString={formula} key={formula}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                : screens.md && calculationType === "leakRateConversion" ?
                                    <div className={"pl-104"}>
                                        <MathFormula rawString={_.get(leakRateConversionConfig, 'formula')} />
                                        {
                                            leakRateConversionConfig?.formulaAddition ?
                                                <div className={"mt-24"}>
                                                    <MathFormula rawString={_.get(leakRateConversionConfig, 'formulaAddition')} />
                                                </div>
                                            : null
                                        }
                                        <div className={"mt-48"}>
                                            {
                                                leakRateConversionConfig?.formulaLegend.map((formula) => {
                                                    return <MathFormula rawString={formula} key={formula}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default SimpleCalculationFormWrapper;
