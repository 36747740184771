import React from "react";
import {Switch, Route, Redirect, useHistory} from "react-router-dom";
import SimpleCalculationLeakDetectionFormWrapper from "../../components/SimpleCalculationLeakDetectionFormWrapper";
import SimpleCalculationUnitConversionFormWrapper from "../../components/SimpleCalculationUnitConversionFormWrapper";
import SimpleCalculationVacuumCalculationFormWrapper from "../../components/SimpleCalculationVacuumCalculationFormWrapper";
import SimpleCalculationChamberCalculationFormWrapper from "../../components/SimpleCalculationChamberCalculationFormWrapper";


export default function SimpleCalculationRoute() {

    const history = useHistory();

    const onBackToHome = () => {
        history.push('/')
    }

    return (
        <Switch>
            <Route
                path={`/simpleCalculation/vacuumCalculation/:calculationId/form`}
                component={() => <SimpleCalculationVacuumCalculationFormWrapper onBackToHome={onBackToHome}/>}
            />
            <Redirect
                from="/simpleCalculation/vacuumCalculation/:calculationId"
                to={"/simpleCalculation/vacuumCalculation/:calculationId/form"}
            />
            <Route
                path={`/simpleCalculation/chamberCalculation/:calculationId/form`}
                component={() => <SimpleCalculationChamberCalculationFormWrapper onBackToHome={onBackToHome}/>}
            />
            <Redirect
                from="/simpleCalculation/chamberCalculation/:calculationId"
                to={"/simpleCalculation/chamberCalculation/:calculationId/form"}
            />
            <Route
                path={`/simpleCalculation/leakDetectionCalculation/:calculationId/form`}
                component={() => <SimpleCalculationLeakDetectionFormWrapper onBackToHome={onBackToHome}/>}
            />
            <Redirect
                from="/simpleCalculation/leakDetectionCalculation/:calculationId"
                to={"/simpleCalculation/leakDetectionCalculation/:calculationId/form"}
            />
            <Route
                path={`/simpleCalculation/unitConversion/:calculationId/form`}
                component={() => <SimpleCalculationUnitConversionFormWrapper onBackToHome={onBackToHome}/>}
            />
            <Redirect
                from="/simpleCalculation/unitConversion/:calculationId"
                to={"/simpleCalculation/unitConversion/:calculationId/form"}
            />
        </Switch>
    )
}
