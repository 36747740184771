import React from 'react';
import {Button} from "@dreebit/pv-components";
import {useTranslation} from "react-i18next";
import {Grid} from "antd";

interface Props {
    onBack?: () => void;
    onReset?: () => void;
    onCalculate?: () => void;
    formSubmitOnCalculate?: boolean;
}

const SimpleCalculationActionButtons = ({onBack, onReset, onCalculate, formSubmitOnCalculate}: Props) => {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const backButton = <Button
        className={screens.md ? "" : "mt-8"}
        type={"ghost"}
        style={{width: screens.md ? "auto" : "100%"}}
        htmlType={"button"}
        icon={"arrow_back"}
        iconPosition={"left"}
        onClick={onBack}
    >
        {t("Back")}
    </Button>

    const resetButton = <Button
        className={screens.md ? "" : "mt-8"}
        type={"tertiary"}
        style={{width: screens.md ? "auto" : "100%"}}
        htmlType={"button"}
        icon={"delete"}
        onClick={onReset}
    >
        {t("Reset")}
    </Button>

    const calculateButton = <Button
        className={screens.md ? "ml-8" : "mt-8"}
        type={"primary"}
        style={{width: screens.md ? "auto" : "100%"}}
        htmlType={(formSubmitOnCalculate && !onCalculate) ? "submit" : "button"}
        onClick={(onCalculate && !formSubmitOnCalculate) ? onCalculate : undefined}
        icon={"calculate"}
    >
        {t("Calculate")}
    </Button>

    if (screens.md) {
        return (
            <div className={"flex flex-space-between pt-16"}>
                {
                    onBack ? backButton : null
                }
                <div className={"flex flex-end"}>
                    {
                        onReset ? resetButton : null
                    }
                    {
                        (onCalculate || formSubmitOnCalculate) ? calculateButton : null
                    }
                </div>
            </div>
        );
    }

    return (
        <div className={"flex flex-col pt-16"}>
            {
                onBack ? backButton : null
            }
            {
                onReset ? resetButton : null
            }
            {
                (onCalculate || formSubmitOnCalculate) ? calculateButton : null
            }
        </div>
    );
}

export default SimpleCalculationActionButtons;
