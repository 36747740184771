import React, {useState} from 'react';
import {Collapse, Grid, Modal} from "antd";
import {useTranslation} from "react-i18next";
import ShowMoreAndLessButton from "../ShowMoreAndLessButton";
import "./index.css";
import {Button, MaterialIcon} from "@dreebit/pv-components";
import {useMutation} from "@apollo/client";
import {CREATE_CALCULATION} from "../../graphql/mutations/createCalculation";
import {useHistory, useParams} from "react-router-dom";
import _ from "lodash";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {DELETE_CALCULATION} from "../../graphql/mutations/deleteCalculation";
import SolutionListItem from "./listItem";
import SavedCalculationsList from "../SavedCalculationsList";
import CalculationValuesList from "../CalculationValuesList";
import AddCollectionCalculationForm from "../AddCollectionCalculationForm";
import {CREATE_CALCULATION_RESULT} from "../../graphql/mutations/createCalculationResult";

interface Props {
    solutionList: any[],
    activeSolutionIndizes: string[],
    colorConfig: string[],
    onChange: (id: string) => void,
    isComparisonList?: boolean,
    calculationCollection?: any,
    calculationMode: string,
    calculationType: string
}

export default function SolutionCheckboxList({solutionList, activeSolutionIndizes, colorConfig, onChange, isComparisonList, calculationCollection, calculationMode, calculationType}: Props) {

    const {t} = useTranslation();
    const {collectionId} = useParams<any>();
    const history = useHistory();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const [createCalculationResult] = useMutation(CREATE_CALCULATION_RESULT)
    const [createCalculation] = useMutation(CREATE_CALCULATION);
    const [deleteCalculation] = useMutation(DELETE_CALCULATION);

    const minListLength = 4;
    const [showAll, setShowAll] =useState(!!isComparisonList);
    const [isNewCalculationModalVisible, setNewCalculationModalVisibility] = useState(false)
    const [isSavedCalculationsModalVisible, setSavedCalculationsModalVisibility] = useState(false);

    const solutions = solutionList.slice(0, showAll ? solutionList.length : minListLength);
    const allSolutions = _.get(solutionList, 'solutions', [])
    const unfinishedCalculations = calculationCollection.calculations.filter((calc: any) => {
        return !calc.valid && !(allSolutions.find((solution: any) => _.get(solution, 'calculation.id') !== calc.id))
    })

    const addNewCalculationToCollection = (copyFromId?: string, isSavedCalculation?: boolean) => {
        createCalculation({
            variables: {
                calculationCollectionId: collectionId,
                calculationMode: calculationMode,
                calculationType: calculationType,
                copyFrom: copyFromId ?
                    {
                        calculationId: copyFromId,
                        valueKeys: []
                    }
                : undefined
            },
            refetchQueries: ["CalculationResultCurves"]
        }).then((res) => {
            const newCalcId = _.get(res, 'data.createCalculation.id')
            if (newCalcId && isSavedCalculation === false) {
                if (copyFromId) {
                    history.push(`/${calculationMode}/${calculationType}/${newCalcId}/pumpSystem`)
                } else {
                    history.push(`/${calculationMode}/${calculationType}/${newCalcId}`)
                }
            } else if (isSavedCalculation) {
                _createResult(newCalcId)
            }
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Error adding a new calculation')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    const editCalculation = (calculationId: string) => {
        history.push(`/${calculationMode}/${calculationType}/${calculationId}`)
    }

    const removeCalculationFromCollection = (calculationId: string) => {
        deleteCalculation({
            variables: {
                ids: [calculationId]
            },
            refetchQueries: ["CalculationResultCurves"]
        }).then((res) => {
            const success = _.get(res, 'data.deleteCalculation.success')
            const message = _.get(res, 'data.deleteCalculation.message')

            if (success) {
                toast.success(<ToastContent
                    status={"success"}
                    text={message}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    text={message}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }

        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Error adding a new calculation')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    const _createResult = (calculationId: string) => {
        createCalculationResult({
            variables: {
                id: calculationId,
            },
            refetchQueries: ["CalculationResultCurves"],
            awaitRefetchQueries: true
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Calculation error')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    }

    return (
        <div className={'solution-checkbox-list'}>
            <div className={'flex flex-col'}>
                {
                    isComparisonList ?
                        <div>
                            {
                                solutions.map((solution: any, index: number) => {
                                    return <div className={`solution-list-item-wrapper`} key={index}>
                                        <Collapse
                                            className={"solution-collapse"}
                                            expandIconPosition={"right"}
                                            ghost={true}
                                            expandIcon={({ isActive }) => <div><MaterialIcon type={isActive ? "keyboard_arrow_up" : "keyboard_arrow_down"} /></div>}
                                        >
                                            <Collapse.Panel
                                                className={"solution-collapse-panel"}
                                                key={1}
                                                header={
                                                    <SolutionListItem
                                                        solution={solution}
                                                        isBypassCollection={_.get(solution, 'calculation.bypassCalculation', false)}
                                                        activeSolutionIndizes={activeSolutionIndizes}
                                                        colorConfig={colorConfig}
                                                        index={index}
                                                        onCheck={(id) => onChange(id)}
                                                        onEdit={(calculationId) => editCalculation(calculationId)}
                                                        onRemove={solutions.length !== 1 ? (calculationId) => removeCalculationFromCollection(calculationId) : undefined}
                                                    />
                                                }
                                            >
                                                <CalculationValuesList calculationValues={_.get(solution, 'calculation.values')} />
                                            </Collapse.Panel>
                                        </Collapse>
                                    </div>
                                })
                            }

                            {
                                unfinishedCalculations ?
                                    unfinishedCalculations.map((calc: any, index: number) => {
                                        return <div className={`solution-list-item-wrapper`} key={index}>
                                            <Collapse
                                                className={"solution-collapse"}
                                                expandIconPosition={"right"}
                                                expandIcon={({ isActive }) => <div><MaterialIcon type={isActive ? "keyboard_arrow_up" : "keyboard_arrow_down"} /></div>}
                                            >
                                                <Collapse.Panel
                                                    className={"solution-collapse-panel"}
                                                    key={1}
                                                    header={
                                                        <SolutionListItem
                                                            calculation={calc}
                                                            activeSolutionIndizes={activeSolutionIndizes}
                                                            incomplete={true}
                                                            colorConfig={colorConfig}
                                                            index={index}
                                                            onCheck={(id) => onChange(id)}
                                                            onEdit={(calculationId) => editCalculation(calculationId)}
                                                            onRemove={(calculationId) => removeCalculationFromCollection(calculationId)}
                                                        />
                                                    }
                                                >
                                                    <CalculationValuesList calculationValues={_.get(calc, 'values')} />
                                                </Collapse.Panel>
                                            </Collapse>
                                        </div>
                                    })
                                    : null
                            }
                        </div>
                    :
                        <div>
                            {
                                solutions.map((solution: any, index: number) => {
                                    return <div className={"solution-list-item-wrapper"} key={index} style={{padding: "12px 0"}}>
                                        <SolutionListItem
                                            solution={solution}
                                            activeSolutionIndizes={activeSolutionIndizes}
                                            colorConfig={colorConfig}
                                            index={index}
                                            onCheck={(id) => onChange(id)}
                                        />
                                    </div>
                                })
                            }
                        </div>
                }

                {
                    solutionList.length > minListLength && !isComparisonList ?
                        <ShowMoreAndLessButton
                            className={'mt-24'}
                            extended={showAll}
                            toggle={() => setShowAll(!showAll)}
                        />
                    : null
                }

                {
                    isComparisonList ?
                        <div className={"mt-24"}>
                            <div className={"flex"} style={{color: "#7a7a7a"}}>
                                <MaterialIcon type={"info_outline"} style={{marginRight: 7}} />
                                <div>
                                    <span>{t("Comparison_add_to_result_text")}</span>
                                    <span style={{display: "inline-block"}}>&nbsp;</span>
                                    <span className={"font-bold"}>{t("Comparison_add_to_result_hint")}</span>
                                </div>

                            </div>
                            <div className={`flex ${screens.lg ? 'flex-row mt-24' : 'flex-col'}`}>
                                <Button
                                    className={`${screens.lg ? '' : 'mt-24'}`}
                                    style={{width: screens.lg ? 'auto' : '100%'}}
                                    type={"tertiary"}
                                    icon={"add"}
                                    disabled={(solutions.length + unfinishedCalculations.length) >= 10}
                                    onClick={() => setNewCalculationModalVisibility(true)}
                                >
                                    {t('Add new calculation')}
                                </Button>
                                <Button
                                    className={`${screens.lg ? 'ml-8' : 'mt-24'}`}
                                    style={{width: screens.lg ? 'auto' : '100%'}}
                                    type={"tertiary"}
                                    icon={"add"}
                                    disabled={(solutions.length + unfinishedCalculations.length) >= 10}
                                    onClick={() => setSavedCalculationsModalVisibility(true)}
                                >
                                    {t('Add saved calculation')}
                                </Button>
                            </div>
                        </div>

                    : null
                }

            </div>

            <Modal
                title={t('Add new calculation')}
                visible={isNewCalculationModalVisible}
                onCancel={() => setNewCalculationModalVisibility(false)}
                footer={null}
                width={600}
            >
                <AddCollectionCalculationForm
                    solutions={solutions}
                    onAddCalculation={(calculationId) => addNewCalculationToCollection(calculationId, false)}
                />
            </Modal>
            <Modal
                title={t('Add saved calculation')}
                visible={isSavedCalculationsModalVisible}
                onCancel={() => setSavedCalculationsModalVisibility(false)}
                footer={null}
                width={700}
            >
                <SavedCalculationsList
                    calculationMode={calculationMode}
                    calculationType={calculationType}
                    childrenCount={1}
                    showCalculationType={false}
                    onSelect={(calculationId) => addNewCalculationToCollection(calculationId, true)}
                    hideDelete={true}
                />
            </Modal>
        </div>
    )
}
