import React, {useRef, useState} from "react";
import {Form, FormInstance, Grid, Modal} from "antd";
import useChamberStep from "../../hooks/useChamberStep";
import DesorptionCarousel from "../DesorptionCarousel";
import {useTranslation} from "react-i18next";
import DesorptionRateSvg from "../Svg/DesorptionRateSvg";
import DesorptionSlider from "../DesorptionSlider";
import _ from "lodash";
import {convertToFormValues, convertToSchemaValues} from "../../utils/convertDesorptionRateForm";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_SURFACE} from "../../graphql/mutations/createSurface";
import SavedSurfacesList from "../SavedSurfacesList";
import {defaultDesorptionUnit, defaultSizeUnit} from "../../config/defaultValues";
import {CURRENT_USER} from "../../graphql/queries/currentUser";
import {stepConfig} from "../../config/pumpFinderChamberEvacuationStepConfig";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {generateSliderConfig} from "../../utils/generateSliderConfig";
import SubmitTypeFormItem from "../SubmitTypeFormItem";
import {SURFACE_LIST} from "../../graphql/queries/surfaceList";

interface Props {
    form: FormInstance,
    stepId: string,
    onSubmit: (values: any, skipFormatting?: boolean) => void,
    fieldValidations: any[],
    initialValues: {[key: string]: any},
    onMobileContent: (content: string) => void
    standardDesorptionRate: number
}

export default function ChamberDesorptionRateContent({form, stepId, onSubmit, fieldValidations, initialValues, onMobileContent, standardDesorptionRate}: Props) {

    const {t} = useTranslation();
    const step = useChamberStep(stepConfig, stepId);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const [surfacesVisible, setSurfacesVisible] = useState(false);
    const [surfaceIndex, setSurfaceIndex] = useState(0);
    const [surfaces, setSurfaces] = useState(convertToFormValues(initialValues, t));

    const sliderConfig = generateSliderConfig(_.get(surfaces[surfaceIndex], 'desorptionRateUnit', defaultDesorptionUnit))

    const {loading, error, data} = useQuery(CURRENT_USER, {});

    const currentUser = _.get(data, 'currentUser');

    const [createSurfaceMutation] = useMutation(CREATE_SURFACE);

    const sliderRef = useRef();

    const toggleSurfaceVisibility = () => {
        setSurfacesVisible(!surfacesVisible);
    };

    const addSurface = () => {
        setSurfaces([...surfaces, {
            desorptionRate: standardDesorptionRate,
            desorptionRateUnit: defaultDesorptionUnit,
            desorptionSurfaceName: '',
            desorptionSurfaceSize: '',
            desorptionSurfaceSizeUnit: defaultSizeUnit
        }]);

        if(sliderRef && sliderRef.current) {
            // @ts-ignore
            sliderRef.current.goTo(surfaces.length);
        }
    };

    const editSurface = (changes: any) => {
        let items = [...surfaces];

        items[surfaceIndex] = {
            ...items[surfaceIndex],
            ...changes
        };

        setSurfaces(items);
    };

    const saveSurface = (values: any) => {
        createSurfaceMutation({
            variables: {
                input: {
                    title: values.desorptionSurfaceName,
                    desorptionRate: parseFloat(values.desorptionRate),
                    desorptionRateUnit: values.desorptionRateUnit,
                }
            },
            refetchQueries: [
                {
                    query: SURFACE_LIST
                }
            ]
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            toast.error(<ToastContent
                status={"error"}
                headline={t('Error saving surface')}
                text={`${errorMessage}`}
            />, {
                autoClose: 7000,
                pauseOnHover: true
            })
        })
    };

    const deleteSurface = (index: number) => {
        let items = [...surfaces];

        if(items.length > 1){
            items.splice(index, 1);

            if(sliderRef && sliderRef.current) {
                // @ts-ignore
                sliderRef.current.prev();
            }

            setSurfaces(items);
        }
    };

    const debounced = _.debounce((changedValues: any, allValues: any) => {
        let tmp = changedValues;
        const changedDesorptionRate = parseLocalizedFloat(changedValues.desorptionRate) || null
        const desorptionRateChangedToNull = changedValues.desorptionRate === "" && !changedDesorptionRate
        const min = sliderConfig.minExponential;
        const max = sliderConfig.maxExponential;

        if (changedDesorptionRate && changedDesorptionRate < min) {
            tmp = {
                ...tmp,
                desorptionRate: min
            }
        } else if (changedDesorptionRate && changedDesorptionRate > max) {
            tmp = {
                ...tmp,
                desorptionRate: max
            }
        } else if (changedDesorptionRate && min <= changedDesorptionRate && changedDesorptionRate<= max) {
            tmp = {
                ...tmp,
                desorptionRate: changedDesorptionRate
            }
        }

        // extra method onChangeDesorptionUnit to change the unit because one method works with debounce and the other not
        if (!changedValues.desorptionRateUnit && !desorptionRateChangedToNull) {
            editSurface(tmp);
        }
    }, 2000);

    const onChangeDesorptionUnit = (unit: string) => {
        editSurface({
            desorptionRateUnit: unit
        });
    }

    return (
        <>
            <div>
                <Form
                    id={`form-${stepId}`}
                    form={form}
                    onFinish={(values) => {
                        const data = {
                            desorption: convertToSchemaValues(surfaces),
                            submitType: _.get(values, 'submitType', null)
                        };
                        onSubmit(data, true);
                    }}
                >
                    <SubmitTypeFormItem />
                    <h3 className={'font-bold'}>{t(step.title || "")}</h3>
                    <div className={"mv-24"}>
                        {Array.isArray(step?.subtitle) ?
                            <p>{t(step.subtitle[0])}</p>
                            : <p>{t(step.subtitle || "")}</p>
                        }
                    </div>
                    <div style={{paddingLeft: '6px', paddingRight: '6px'}}>
                        <DesorptionRateSvg/>
                    </div>
                    <DesorptionSlider
                        value={_.get(surfaces[surfaceIndex], 'desorptionRate', sliderConfig.defaultMark)}
                        onChange={(newDesorptionRate: any) => {
                            if (newDesorptionRate < sliderConfig.minExponential) {
                                editSurface({
                                    desorptionRate: sliderConfig.minExponential
                                });
                            } else if (newDesorptionRate > sliderConfig.maxExponential) {
                                editSurface({
                                    desorptionRate: sliderConfig.maxExponential
                                });
                            } else {
                                editSurface({
                                    desorptionRate: newDesorptionRate.toExponential(0)
                                });
                            }
                        }}
                        config={sliderConfig}
                    />
                    <div className={'pt-24'}>
                        <DesorptionCarousel
                            surfaces={surfaces}
                            onAddSurface={addSurface}
                            onSaveSurface={saveSurface}
                            onDeleteSurface={deleteSurface}
                            onChangeSurfaceIndex={setSurfaceIndex}
                            onList={screens.md ? toggleSurfaceVisibility : () => onMobileContent("savedSurfaces")}
                            onValuesChange={debounced}
                            onChangeDesorptionUnit={(value) => onChangeDesorptionUnit(value)}
                            sliderRef={sliderRef}
                            step={step}
                            fieldValidations={fieldValidations}
                            currentUser={currentUser}
                        />
                    </div>
                    <div className={"mt-32"}>
                        {Array.isArray(step?.subtitle) ?
                            step.subtitle.slice(1, step.subtitle.length).map((sub, index) => {
                                return <p key={index}>{t(sub)}</p>
                            })
                            : null
                        }
                    </div>
                </Form>
            </div>
            <Modal
                title={t('Open saved materials')}
                visible={surfacesVisible}
                onCancel={toggleSurfaceVisibility}
                footer={null}
                width={720}
            >
                <SavedSurfacesList
                    onSelectSurface={(surface) => {
                        editSurface({
                            desorptionSurfaceName: surface.title,
                            desorptionRate: surface.desorptionRate,
                            desorptionRateUnit: surface.desorptionRateUnit
                        })
                        toggleSurfaceVisibility();
                    }}
                />
            </Modal>
        </>
    )
}

