import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Col, Form, Grid, Row, Select} from "antd";
import _ from "lodash";
import "./index.css";
import MathFormula from "../MathFormula";
import ConductancePipeForm from "../SimpleCalculationVacuumCalculationForm/conductancePipe";
import ConductanceOrificeForm from "../SimpleCalculationVacuumCalculationForm/conductanceOrifice";
import EffectiveSuctionSpeedForm from "../SimpleCalculationVacuumCalculationForm/effectiveSuctionSpeed";
import DesorptionCalculationForm from "../SimpleCalculationVacuumCalculationForm/desorption";
import EvacuationCalculationForm from "../SimpleCalculationVacuumCalculationForm/evacuation";
import GasFlowPressureSuctionSpeedForm from "../SimpleCalculationVacuumCalculationForm/gasFlowPressureSuctionSpeed";

interface Props {
    onBackToHome: () => void
}

const Components: {[key: string]: any} = {
    "ConductancePipeForm": ConductancePipeForm,
    "ConductanceOrificeForm": ConductanceOrificeForm,
    "EffectiveSuctionSpeedForm": EffectiveSuctionSpeedForm,
    "EvacuationCalculationForm": EvacuationCalculationForm,
    "DesorptionCalculationForm": DesorptionCalculationForm,
    "GasFlowPressureSuctionSpeedForm": GasFlowPressureSuctionSpeedForm,
}

const SimpleCalculationVacuumCalculationFormWrapper = ({onBackToHome}: Props) => {

    const {t, i18n} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const vacuumCalculationConfig = {
        headline: t('Vacuum calculations'),
        calculations: [
            {
                type: "Conductance (pipe)",
                mathFormula: (i18n.language === 'de' ?
                    String.raw`C_{Rohr} = \frac{3,6 \cdot r^3}{l} \cdot \bigg(0,039 \cdot \frac{r \cdot p_m}{\eta} + 30\sqrt{\frac{T}{M}}\bigg)` :
                    String.raw`C_{pipe} = \frac{3,6 \cdot r^3}{l} \cdot \bigg(0,039 \cdot \frac{r \cdot p_m}{\eta} + 30\sqrt{\frac{T}{M}}\bigg)`
                ),
                formulaLegend: [
                    String.raw`r...${t('Radius')}`,
                    (i18n.language === 'de' ? String.raw`l...L\ddot{a}nge` : String.raw`l...Length`),
                    (i18n.language === 'de' ? String.raw`p_m...Mittlerer \: Druck` : String.raw`p_m...Medium \: pressure`),
                    (i18n.language === 'de' ? String.raw`\eta...Viskosit\ddot{a}t \: (Gas)` : String.raw`\eta...Viscosity \: (gas)`),
                    String.raw`T...${t('Temperature')}`,
                    (i18n.language === 'de' ? String.raw`M...Molekulare \: Masse \: (Gas)` : String.raw`M...Molecular \: mass \: (gas)`),
                ],
                formComponent: "ConductancePipeForm",
                notes: ''
            },
            {
                type: "Conductance (orifice)",
                mathFormula: (i18n.language === 'de' ?
                        String.raw`C_{Bl,\: mol} = \frac{d^2 \cdot \pi}{16} \cdot \sqrt{\frac{8 R T}{\pi M}}` :
                        String.raw`C_{or,\: mol} = \frac{d^2 \cdot \pi}{16} \cdot \sqrt{\frac{8 R T}{\pi M}}`
                ),
                formulaLegend: [
                    String.raw`d...${t('Diameter')}`,
                    (i18n.language === 'de' ? String.raw`R...Gaskonstante` : String.raw`R...Gas \: constant`),
                    (i18n.language === 'de' ? String.raw`T...Gastemperatur` : String.raw`T...Gas \: temperature`),
                    (i18n.language === 'de' ? String.raw`M...Molekulare \: Masse \: (Gas)` : String.raw`M...Molecular \: mass \: (gas)`),
                ],
                formComponent: "ConductanceOrificeForm",
                notes: ''
            },
            {
                type: "Effective suction speed",
                mathFormula: String.raw`S_{eff} = \frac{S \cdot C}{S + C}`,
                formulaLegend: [
                    (i18n.language === 'de' ? String.raw`S...Saugverm\ddot{o}gen` : String.raw`S...Suction \: speed`),
                    String.raw`C...${t('Conductance')}`,
                ],
                formComponent: "EffectiveSuctionSpeedForm",
                notes: ''
            },
            {
                type: "Evacuation",
                mathFormula: String.raw`t = \frac{V}{S} \cdot \ln(\frac{p(1)}{p(2)})`,
                formulaLegend: [
                    String.raw`V...${t('Volume')}`,
                    (i18n.language === 'de' ? String.raw`S...Saugverm\ddot{o}gen` : String.raw`S...Suction \: speed`),
                    (i18n.language === 'de' ? String.raw`t...Abpumpzeit` : String.raw`t...Pump \: down \: time`),
                    (i18n.language === 'de' ? String.raw`p(1)...Startdruck` : String.raw`p(1)...Starting \: pressure`),
                    (i18n.language === 'de' ? String.raw`p(2)...Zieldruck` : String.raw`p(2)...Target \: pressure`),
                ],
                formComponent: "EvacuationCalculationForm",
                notes: ''
            },
            {
                type: "Desorption",
                mathFormula: String.raw`t = \frac{q \cdot A \cdot t(0)}{S \cdot p}`,
                mathFormulaAddition: String.raw`Q = q \cdot A`,
                formulaLegend: [
                    (i18n.language === 'de' ? String.raw`q...Desorptionsrate` : String.raw`q...Desorption \: rate`),
                    (i18n.language === 'de' ? String.raw`A...Oberfl\ddot{a}chengr\ddot{o} \beta e` : String.raw`A...Surface \: size`),
                    (i18n.language === 'de' ? String.raw`Q...Integrale \: Ausgasrate` : String.raw`Q...Integral \: outgassing \: rate`),
                    (i18n.language === 'de' ? String.raw`t(0)...Startzeit` : String.raw`t(0)...Starting \: time`),
                    (i18n.language === 'de' ? String.raw`S...Saugverm\ddot{o}gen` : String.raw`S...Suction \: speed`),
                    (i18n.language === 'de' ? String.raw`p...Arbeitsdruck` : String.raw`p...Working \: pressure`),
                ],
                formComponent: "DesorptionCalculationForm",
                notes: ''
            },
            {
                type: "Gas flow / Pressure / Suction speed",
                mathFormula: String.raw`Q = p \cdot S`,
                formulaLegend: [
                    (i18n.language === 'de' ? String.raw`Q...Gasfluss` : String.raw`Q...Gas \: flow`),
                    String.raw`p...${t('Pressure')}`,
                    (i18n.language === 'de' ? String.raw`S...Saugverm\ddot{o}gen` : String.raw`S...Suction \: speed`),
                ],
                formComponent: "GasFlowPressureSuctionSpeedForm",
                notes: ''
            }
        ]
    }

    const [calculationType, setCalculationType] = useState(_.head(vacuumCalculationConfig.calculations));

    const onChangeCalculationType = (type: string) => {
        setCalculationType(vacuumCalculationConfig.calculations.find(x => x.type === type))
    }

    let component = null;
    if (calculationType && typeof Components[calculationType.formComponent] !== "undefined") {
        component = React.createElement(Components[calculationType.formComponent], {
            onBackToHome: onBackToHome,
            onChangeMathFormula: (formula: string) => {setCalculationType({
                ...calculationType,
                mathFormula: formula,
            })}
        });
    }

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h2 className={'font-bold'}>{t('Vacuum Calculator')}</h2>
                    <h3>{t('Perform a simple vacuum calculation')}</h3>
                </div>
                <div className={'mt-32'}>
                    <h4 className={'font-bold mb-24'}>{t('Vacuum calculations')}</h4>
                    <Row gutter={screens.md ? 40 : 0}>
                        <Col span={screens.md ? 14 : 24}>
                            <Form
                                layout={'vertical'}
                                onValuesChange={(values) => onChangeCalculationType(values.calculationType)}
                                initialValues={{calculationType: calculationType?.type}}
                            >
                                <Form.Item name={"calculationType"} label={t('What do you want to calculate?')} required={true}>
                                    <Select>
                                        {
                                            vacuumCalculationConfig.calculations.map((calc, index) => {
                                                return <Select.Option key={index} value={calc.type}>
                                                    {t(calc.type)}
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            {component}
                        </Col>
                        <Col span={screens.md ? 10 : 24}>
                            {
                                screens.md ?
                                    <div className={screens.md ? "pl-104" : "pt-24"}>
                                        <MathFormula rawString={calculationType?.mathFormula || ""}/>
                                        {
                                            calculationType?.mathFormulaAddition ?
                                                <div className={"mt-24"}>
                                                    <MathFormula rawString={_.get(calculationType, 'mathFormulaAddition')} />
                                                </div>
                                            : null
                                        }
                                        <div className={"mt-48"}>
                                            {
                                                calculationType?.formulaLegend.map((formula, index) => {
                                                    return <MathFormula rawString={formula || ""} key={index}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default SimpleCalculationVacuumCalculationFormWrapper;
