import React, {useState} from 'react';
import "./index.css";
import {Col, Form, Row, Select, Radio, Space} from "antd";
import {unitConfig} from "../../config/unitConfig";
import {useTranslation} from "react-i18next";
import NumberInput from "../NumberInput";
import {getFormAttributes} from "./getFormAttributes";
import {Unit} from "../../types";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import _ from "lodash";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    onBackToHome: () => void
    onChangeMathFormula: (formula: string) => void
}

const EvacuationCalculationForm = ({onBackToHome, onChangeMathFormula}: Props) => {

    const calculationName = "evacuation"
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const effectiveSuctionSpeedUnits = unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed");
    const pumpDownTimeUnits = unitConfig.filter((unit: Unit) => unit.category === "time");
    const [selectedCalculationValue, setSelectedCalculationValue] = useState<"effectiveSuctionSpeed" | "pumpDownTime">("pumpDownTime")
    const [calculationDone, setCalculationDone] = useState(false);

    const effectiveSuctionSpeedFormula = String.raw`S = \frac{V}{t} \cdot \ln(\frac{p(1)}{p(2)})`
    const pumpDownTimeFormula = String.raw`t = \frac{V}{S} \cdot \ln(\frac{p(1)}{p(2)})`

    const attributes = getFormAttributes(calculationName, t);
    let initialValues = {}

    attributes.forEach((attribute) => {
        if (attribute.units && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: null,
                [`${attribute.id}_unit`]: attribute.units[0].id
            })
        } else if (attribute.options && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: attribute.options[0].id
            })
        }
    })

    initialValues = {
        ...initialValues,
        ["effectiveSuctionSpeed_unit"]: effectiveSuctionSpeedUnits[0].id,
        ["pumpDownTime_unit"]: pumpDownTimeUnits[0].id,

    }

    const calculateResult = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const V = convertUnitToSi(parseLocalizedFloat(_.get(values, 'volume')),_.get(values, 'volume_unit'));
                const p1 = convertUnitToSi(parseLocalizedFloat(_.get(values, 'startingPressure')), _.get(values, 'startingPressure_unit'));
                const p2 = convertUnitToSi(parseLocalizedFloat(_.get(values, 'targetPressure')), _.get(values, 'targetPressure_unit'));

                if (selectedCalculationValue === "effectiveSuctionSpeed" && V && p1 && p2) {
                    // S = V / t * ln(p1 / p2)
                    const t = convertUnitToSi(parseLocalizedFloat(_.get(values, 'pumpDownTime')), _.get(values, 'pumpDownTime_unit'));

                    if (t) {
                        let calculatedResult = V / t * Math.log(p1 / p2);
                        // convert result to wanted unit
                        calculatedResult = convertUnitFromSi(calculatedResult, _.get(values, 'effectiveSuctionSpeed_unit'))
                        calculatedResult = roundNumber(calculatedResult)
                        form.setFieldsValue({effectiveSuctionSpeed: calculatedResult})
                    }
                } else if (selectedCalculationValue === "pumpDownTime" && V && p1 && p2) {
                    // t = V / S * ln(p1 / p2)
                    const S = convertUnitToSi(parseLocalizedFloat(_.get(values, 'effectiveSuctionSpeed')),_.get(values, 'effectiveSuctionSpeed_unit'));

                    if (S) {
                        let calculatedResult = V / S * Math.log(p1 / p2);
                        // convert result to wanted unit
                        calculatedResult = convertUnitFromSi(calculatedResult, _.get(values, 'pumpDownTime_unit'))
                        calculatedResult = roundNumber(calculatedResult)
                        form.setFieldsValue({pumpDownTime: calculatedResult})
                    }
                }

                setCalculationDone(true);
            });
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"form-border-bottom"}
                layout={"vertical"}
                form={form}
                initialValues={initialValues}
            >
                <Row gutter={16} className={"form-border-bottom"}>
                    {
                        attributes.map(attribute => {
                            return <Col
                                key={attribute.id}
                                xs={{span: attribute.width.xs}}
                                md={{span: attribute.width.md}}
                            >
                                {
                                    attribute.units && attribute.type === "number" ?
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}`} label={attribute.label} required={true} rules={[{ required: true }]}>
                                                    <NumberInput />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}_unit`} label={" "} required={true}>
                                                    <Select tabIndex={-1}>
                                                        {attribute.units.map((unit: Unit) => {
                                                            return <Select.Option
                                                                value={unit.id}
                                                                key={unit.value}
                                                            >
                                                                {unit.display}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    : null
                                }
                            </Col>
                        })
                    }
                </Row>

                <div className={"result-row"}>
                    <span className={"font-bold"}>{t("Select a value to be calculated")}:</span>
                    <Radio.Group
                        value={selectedCalculationValue}
                        onChange={(e) => {
                            const value = _.get(e, 'target.value')
                            setSelectedCalculationValue(value)
                            form.setFieldsValue({
                                [value]: null
                            })
                            onChangeMathFormula(value === "pumpDownTime" ? pumpDownTimeFormula : effectiveSuctionSpeedFormula)
                        }}
                    >
                        <Space direction={"vertical"}>
                            <Row gutter={16} align={"middle"}>
                                <Col xs={24} md={12}>
                                    <Radio value={"effectiveSuctionSpeed"}>
                                        <span className={selectedCalculationValue === "effectiveSuctionSpeed" ? "font-bold" : ""}>
                                            {t("Effective suction speed")}:
                                        </span>
                                    </Radio>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name={`effectiveSuctionSpeed`}
                                        label={" "}
                                        required={selectedCalculationValue !== "effectiveSuctionSpeed"}
                                        rules={selectedCalculationValue === "effectiveSuctionSpeed" ? [] : [{ required: true }]}
                                    >
                                        <NumberInput
                                            className={calculationDone && selectedCalculationValue === "effectiveSuctionSpeed" ? "success-border" : ""}
                                            disabled={selectedCalculationValue === "effectiveSuctionSpeed"}
                                            exponentialbreakpoint={0.01}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item name={`effectiveSuctionSpeed_unit`} label={" "} required={true}>
                                        <Select className={"full-width"}>
                                            {
                                                effectiveSuctionSpeedUnits.map((unit: Unit) => {
                                                    return <Select.Option value={unit.id} key={unit.value}>
                                                        {unit.display}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Radio value={"pumpDownTime"}>
                                        <span className={selectedCalculationValue === "pumpDownTime" ? "font-bold" : ""}>
                                            {t("Pump down time")}:
                                        </span>
                                    </Radio>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name={`pumpDownTime`}
                                        label={" "}
                                        required={selectedCalculationValue !== "pumpDownTime"}
                                        rules={selectedCalculationValue === "pumpDownTime" ? [] : [{ required: true }]}
                                    >
                                        <NumberInput
                                            className={calculationDone && selectedCalculationValue === "pumpDownTime" ? "success-border" : ""}
                                            disabled={selectedCalculationValue === "pumpDownTime"}
                                            exponentialbreakpoint={0.01}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Item name={`pumpDownTime_unit`} label={" "} required={true}>
                                        <Select className={"full-width"}>
                                            {
                                                pumpDownTimeUnits.map((unit: Unit) => {
                                                    return <Select.Option value={unit.id} key={unit.value}>
                                                        {unit.display}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Space>
                    </Radio.Group>
                </div>
            </Form>
            <SimpleCalculationActionButtons
                onBack={onBackToHome}
                onReset={() => {
                    setCalculationDone(false)
                    form.resetFields()
                }}
                onCalculate={() => calculateResult()}
            />
        </div>
    );
}

export default EvacuationCalculationForm;


