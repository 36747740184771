import React from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {SURFACE_LIST} from "../../graphql/queries/surfaceList";
import _ from "lodash";
import {Loader, Button, LoadingSpinner} from "@dreebit/pv-components";
import {ArrowRightOutlined, DeleteFilled} from "@ant-design/icons";

interface Props {
    calculationType: string
}

export default function SavedSurfacesMobileModal({calculationType}: Props) {

    const {t} = useTranslation();

    const {loading, data} = useQuery(SURFACE_LIST, {

    });

    const surfaces = _.get(data, 'surfaceList.surfaces')

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div
            className={'full-width pt-16'}
            style={{overflowY: "scroll"}}
        >
            {surfaces ? surfaces.map((surface: any) => {
                return <div
                    key={surface.id}
                    className={'pv-16'}
                    style={{borderBottom: '1px solid #ccc'}}
                >
                    <div>
                        <span>{t('Name')}</span>
                        <span className="font-bold pl-16">{surface.title || t('No title')}</span>
                    </div>
                    <div>
                        <span>{t('Desorption rate')}</span>
                        <span className="pl-8">{surface.desorptionRate} {surface.desorptionRateUnit}</span>
                    </div>
                    <div className="flex-row flex-space-between">
                        <Button
                            type={'ghost'}
                            onClick={() => {}}
                            icon={"arrow_forward"}
                        >
                            {t('Select')}
                        </Button>
                        <Button type={'ghost'} icon={"delete"} />
                    </div>
                </div>
            }) : null}
        </div>
    )
}
