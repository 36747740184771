import {stepConfig} from "../config/pumpFinderChamberEvacuationStepConfig";
import _ from "lodash";
import {unitConfig} from "../config/unitConfig";

// returns the display unit for a raw unit format given the attributeId from the stepConfig
export const getDisplayUnit = (attributeId: string, rawUnit: string): string => {

    const filteredAttributes = _(stepConfig)
        .map('attributes')
        .flatten()
        .filter({ id: attributeId })
        .value()

    const filteredUnits = filteredAttributes[0]?.units?.filter(unit => unit.value === rawUnit)

    if (filteredUnits && filteredUnits.length === 1) {
        return filteredUnits[0].display
    } else {
        return rawUnit
    }
}

export const getDisplayUnitWithoutAttribute = (rawUnit: string): string => {
    const matchingUnit = unitConfig.find((x: { value: string }) => x.value === rawUnit)

    // @ts-ignore
    return matchingUnit.display;
}
