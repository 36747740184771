import {GasExtended} from "../types";

export const gasConfig: GasExtended[] = [
    {
        "id": "H2",
        "gasName": "Hydrogen",
        "chemicalFormula": "H2",
        "molarMass_gMol": 2.02,
        "viscosity_muPa_s": 8.8,
        "molarMass": 0.00202,
        "viscosity": 0.0000088,
        "label": "H2 - Hydrogen (H2)"
    },
    {
        "id": "95% N2, 5% H2",
        "gasName": "Forming Gas 95/5",
        "chemicalFormula": "95% N2, 5% H2",
        "molarMass_gMol": 26.7,
        "viscosity_muPa_s": 17.5,
        "molarMass": 0.0267,
        "viscosity": 0.0000175,
        "label": "95% N2, 5% H2 - Forming Gas 95/5 (95% N2, 5% H2)"
    },
    {
        "id": "He",
        "gasName": "Helium",
        "chemicalFormula": "He",
        "molarMass_gMol": 4,
        "viscosity_muPa_s": 19.6,
        "molarMass": 0.004,
        "viscosity": 0.0000196,
        "label": "He - Helium (He)"
    },
    {
        "id": "NH3",
        "gasName": "Ammonia",
        "chemicalFormula": "NH3",
        "molarMass_gMol": 17.03,
        "viscosity_muPa_s": 9.91,
        "molarMass": 0.01703,
        "viscosity": 0.00000991,
        "label": "NH3 - Ammonia (NH3)"
    },
    {
        "id": "H2O",
        "gasName": "Water",
        "chemicalFormula": "H2O",
        "molarMass_gMol": 18.02,
        "viscosity_muPa_s": 9,
        "molarMass": 0.01802,
        "viscosity": 0.000009,
        "label": "H2O - Water (H2O)"
    },
    {
        "id": "Ne",
        "gasName": "Neon",
        "chemicalFormula": "Ne",
        "molarMass_gMol": 20.18,
        "viscosity_muPa_s": 31.11,
        "molarMass": 0.02018,
        "viscosity": 0.00003111,
        "label": "Ne - Neon (Ne)"
    },
    {
        "id": "N2",
        "gasName": "Nitrogen",
        "chemicalFormula": "N2",
        "molarMass_gMol": 28.01,
        "viscosity_muPa_s": 17.48,
        "molarMass": 0.02801,
        "viscosity": 0.00001748,
        "label": "N2 - Nitrogen (N2)"
    },
    {
        "id": "-",
        "gasName": "Air",
        "chemicalFormula": "N2+O2+Ar+CO2",
        "molarMass_gMol": 28.96,
        "viscosity_muPa_s": 18.19,
        "molarMass": 0.02896,
        "viscosity": 0.00001819,
        "label": "- - Air (N2+O2+Ar+CO2)"
    },
    {
        "id": "O2",
        "gasName": "Oxygen",
        "chemicalFormula": "O2",
        "molarMass_gMol": 32,
        "viscosity_muPa_s": 20.24,
        "molarMass": 0.032,
        "viscosity": 0.00002024,
        "label": "O2 - Oxygen (O2)"
    },
    {
        "id": "Ar",
        "gasName": "Argon",
        "chemicalFormula": "Ar",
        "molarMass_gMol": 39.95,
        "viscosity_muPa_s": 22.11,
        "molarMass": 0.03995,
        "viscosity": 0.00002211,
        "label": "Ar - Argon (Ar)"
    },
    {
        "id": "CO2",
        "gasName": "Carbon dioxide",
        "chemicalFormula": "CO2",
        "molarMass_gMol": 44.01,
        "viscosity_muPa_s": 14.63,
        "molarMass": 0.04401,
        "viscosity": 0.00001463,
        "label": "CO2 - Carbon dioxide (CO2)"
    },
    {
        "id": "SO2",
        "gasName": "Sulfur dioxide",
        "chemicalFormula": "SO2",
        "molarMass_gMol": 64.06,
        "viscosity_muPa_s": 12.52,
        "molarMass": 0.06406,
        "viscosity": 0.00001252,
        "label": "SO2 - Sulfur dioxide (SO2)"
    },
    {
        "id": "Kr",
        "gasName": "Krypton",
        "chemicalFormula": "Kr",
        "molarMass_gMol": 83.8,
        "viscosity_muPa_s": 25.04,
        "molarMass": 0.0838,
        "viscosity": 0.00002504,
        "label": "Kr - Krypton (Kr)"
    },
    {
        "id": "Xe",
        "gasName": "Xenon",
        "chemicalFormula": "Xe",
        "molarMass_gMol": 131.29,
        "viscosity_muPa_s": 22.6,
        "molarMass": 0.13129,
        "viscosity": 0.0000226,
        "label": "Xe - Xenon (Xe)"
    },
    {
        "id": "SF6",
        "gasName": "Sulfur hexafluoride",
        "chemicalFormula": "SF6",
        "molarMass_gMol": 146.05,
        "viscosity_muPa_s": 15,
        "molarMass": 0.14605,
        "viscosity": 0.000015,
        "label": "SF6 - Sulfur hexafluoride (SF6)"
    },
    {
        "id": "R11",
        "gasName": "Fluorotrichloromethane",
        "chemicalFormula": "CCl3F",
        "molarMass_gMol": 137.37,
        "viscosity_muPa_s": 10.05,
        "molarMass": 0.13737,
        "viscosity": 0.00001005,
        "label": "R11 - Fluorotrichloromethane (CCl3F)"
    },
    {
        "id": "R12",
        "gasName": "Dichlorodifluoromethane",
        "chemicalFormula": "CCl2F2",
        "molarMass_gMol": 120.91,
        "viscosity_muPa_s": 11.63,
        "molarMass": 0.12091,
        "viscosity": 0.00001163,
        "label": "R12 - Dichlorodifluoromethane (CCl2F2)"
    },
    {
        "id": "R1234YF",
        "gasName": "2,3,3,3-tetrafluoropropene",
        "chemicalFormula": "H2C=CF-CF3",
        "molarMass_gMol": 114,
        "viscosity_muPa_s": 12.3,
        "molarMass": 0.114,
        "viscosity": 0.0000123,
        "label": "R1234YF - 2,3,3,3-tetrafluoropropene (H2C=CF-CF3)"
    },
    {
        "id": "R12B1",
        "gasName": "Bromochlorodifluoromethane",
        "chemicalFormula": "CBrClF2",
        "molarMass_gMol": 165.36,
        "viscosity_muPa_s": "",
        "molarMass": 0.16536,
        "viscosity": 0,
        "label": "R12B1 - Bromochlorodifluoromethane (CBrClF2)"
    },
    {
        "id": "R12B2",
        "gasName": "Dibromodifluoromethane",
        "chemicalFormula": "CBr2F2",
        "molarMass_gMol": 209.82,
        "viscosity_muPa_s": "",
        "molarMass": 0.20982,
        "viscosity": 0,
        "label": "R12B2 - Dibromodifluoromethane (CBr2F2)"
    },
    {
        "id": "R13",
        "gasName": "Chlorotrifluoromethane",
        "chemicalFormula": "CClF3",
        "molarMass_gMol": 104.46,
        "viscosity_muPa_s": 16.8,
        "molarMass": 0.10446,
        "viscosity": 0.0000168,
        "label": "R13 - Chlorotrifluoromethane (CClF3)"
    },
    {
        "id": "R13B1",
        "gasName": "Bromotrifluoromethane",
        "chemicalFormula": "CBrF3",
        "molarMass_gMol": 148.91,
        "viscosity_muPa_s": 16.7,
        "molarMass": 0.14891,
        "viscosity": 0.0000167,
        "label": "R13B1 - Bromotrifluoromethane (CBrF3)"
    },
    {
        "id": "R14",
        "gasName": "Tetrafluoromethane",
        "chemicalFormula": "CF4",
        "molarMass_gMol": 88,
        "viscosity_muPa_s": 16.81,
        "molarMass": 0.088,
        "viscosity": 0.00001681,
        "label": "R14 - Tetrafluoromethane (CF4)"
    },
    {
        "id": "R21",
        "gasName": "Dichlorofluoromethane",
        "chemicalFormula": "CHCl2F",
        "molarMass_gMol": 102.92,
        "viscosity_muPa_s": 10.5,
        "molarMass": 0.10292,
        "viscosity": 0.0000105,
        "label": "R21 - Dichlorofluoromethane (CHCl2F)"
    },
    {
        "id": "R22",
        "gasName": "Chlorodifluoromethane",
        "chemicalFormula": "CHClF2",
        "molarMass_gMol": 86.47,
        "viscosity_muPa_s": 12.42,
        "molarMass": 0.08647,
        "viscosity": 0.00001242,
        "label": "R22 - Chlorodifluoromethane (CHClF2)"
    },
    {
        "id": "R23",
        "gasName": "Fluoroform/Trifluoromethane",
        "chemicalFormula": "CHF3",
        "molarMass_gMol": 70.01,
        "viscosity_muPa_s": 13.77,
        "molarMass": 0.07001,
        "viscosity": 0.00001377,
        "label": "R23 - Fluoroform/Trifluoromethane (CHF3)"
    },
    {
        "id": "R30",
        "gasName": "Dichloromethane",
        "chemicalFormula": "CH2Cl2",
        "molarMass_gMol": 84.93,
        "viscosity_muPa_s": "",
        "molarMass": 0.08493,
        "viscosity": 0,
        "label": "R30 - Dichloromethane (CH2Cl2)"
    },
    {
        "id": "R31",
        "gasName": "Chlorofluoromethane",
        "chemicalFormula": "CH2ClF",
        "molarMass_gMol": 68.48,
        "viscosity_muPa_s": "",
        "molarMass": 0.06848,
        "viscosity": 0,
        "label": "R31 - Chlorofluoromethane (CH2ClF)"
    },
    {
        "id": "R32",
        "gasName": "Difluoromethane",
        "chemicalFormula": "CH2F2",
        "molarMass_gMol": 52.02,
        "viscosity_muPa_s": 12.42,
        "molarMass": 0.05202,
        "viscosity": 0.00001242,
        "label": "R32 - Difluoromethane (CH2F2)"
    },
    {
        "id": "R40",
        "gasName": "Chlormethane",
        "chemicalFormula": "CH3Cl",
        "molarMass_gMol": 50.49,
        "viscosity_muPa_s": "",
        "molarMass": 0.05049,
        "viscosity": 0,
        "label": "R40 - Chlormethane (CH3Cl)"
    },
    {
        "id": "R41",
        "gasName": "Fluoromethane",
        "chemicalFormula": "CH3F",
        "molarMass_gMol": 34.03,
        "viscosity_muPa_s": 10.8,
        "molarMass": 0.03403,
        "viscosity": 0.0000108,
        "label": "R41 - Fluoromethane (CH3F)"
    },
    {
        "id": "R50",
        "gasName": "Methane",
        "chemicalFormula": "CH4",
        "molarMass_gMol": 16.04,
        "viscosity_muPa_s": 10.87,
        "molarMass": 0.01604,
        "viscosity": 0.00001087,
        "label": "R50 - Methane (CH4)"
    },
    {
        "id": "R113",
        "gasName": "1,1,2-Trichloro-1,2,2-Trifluoroethane",
        "chemicalFormula": "CCl2F-CClF2",
        "molarMass_gMol": 187.37,
        "viscosity_muPa_s": 9.46,
        "molarMass": 0.18737,
        "viscosity": 0.00000946,
        "label": "R113 - 1,1,2-Trichloro-1,2,2-Trifluoroethane (CCl2F-CClF2)"
    },
    {
        "id": "R113a",
        "gasName": "1,1,1-Trichloro-2,2,2-Trifluoroethande",
        "chemicalFormula": "CF3-CCl3",
        "molarMass_gMol": 187.4,
        "viscosity_muPa_s": "",
        "molarMass": 0.1874,
        "viscosity": 0,
        "label": "R113a - 1,1,1-Trichloro-2,2,2-Trifluoroethande (CF3-CCl3)"
    },
    {
        "id": "R114",
        "gasName": "1,1-Dichloro-1,1,2,2-Tetrafluoroethane",
        "chemicalFormula": "CClF2-CClF2",
        "molarMass_gMol": 170.92,
        "viscosity_muPa_s": 10.67,
        "molarMass": 0.17092,
        "viscosity": 0.00001067,
        "label": "R114 - 1,1-Dichloro-1,1,2,2-Tetrafluoroethane (CClF2-CClF2)"
    },
    {
        "id": "R114a",
        "gasName": "1,1-Dichloro-1,2,2,2-Tetrafluoroethane",
        "chemicalFormula": "CF3-CCl2F",
        "molarMass_gMol": 170.92,
        "viscosity_muPa_s": "",
        "molarMass": 0.17092,
        "viscosity": 0,
        "label": "R114a - 1,1-Dichloro-1,2,2,2-Tetrafluoroethane (CF3-CCl2F)"
    },
    {
        "id": "R115",
        "gasName": "Chloropentafluoroethane",
        "chemicalFormula": "CClF2-CF3",
        "molarMass_gMol": 154.47,
        "viscosity_muPa_s": 12.31,
        "molarMass": 0.15447,
        "viscosity": 0.00001231,
        "label": "R115 - Chloropentafluoroethane (CClF2-CF3)"
    },
    {
        "id": "R116",
        "gasName": "Hexafluoroethane",
        "chemicalFormula": "CF3-CF3",
        "molarMass_gMol": 138.01,
        "viscosity_muPa_s": 13.93,
        "molarMass": 0.13801,
        "viscosity": 0.00001393,
        "label": "R116 - Hexafluoroethane (CF3-CF3)"
    },
    {
        "id": "R122",
        "gasName": "1,2-Difluoro-1,2,2-Trichloroethane",
        "chemicalFormula": "Cl3C-CHF2",
        "molarMass_gMol": 169.38,
        "viscosity_muPa_s": "",
        "molarMass": 0.16938,
        "viscosity": 0,
        "label": "R122 - 1,2-Difluoro-1,2,2-Trichloroethane (Cl3C-CHF2)"
    },
    {
        "id": "R123",
        "gasName": "2,2-Dichloro-1,1,1-Trifluoroethane",
        "chemicalFormula": "CHCl2-CF3",
        "molarMass_gMol": 152.93,
        "viscosity_muPa_s": 10.56,
        "molarMass": 0.15293,
        "viscosity": 0.00001056,
        "label": "R123 - 2,2-Dichloro-1,1,1-Trifluoroethane (CHCl2-CF3)"
    },
    {
        "id": "R123b",
        "gasName": "1,2-Dichloro-1,1,2-Trifluoroethane",
        "chemicalFormula": "CHClF-CClF2",
        "molarMass_gMol": 152.93,
        "viscosity_muPa_s": "",
        "molarMass": 0.15293,
        "viscosity": 0,
        "label": "R123b - 1,2-Dichloro-1,1,2-Trifluoroethane (CHClF-CClF2)"
    },
    {
        "id": "R124",
        "gasName": "2-Chloro-1,1,1,2-Tetrafluoroethane",
        "chemicalFormula": "CHClF-CF3",
        "molarMass_gMol": 136.48,
        "viscosity_muPa_s": 11.39,
        "molarMass": 0.13648,
        "viscosity": 0.00001139,
        "label": "R124 - 2-Chloro-1,1,1,2-Tetrafluoroethane (CHClF-CF3)"
    },
    {
        "id": "R124a",
        "gasName": "1-Chloro-1,1,2,2-Tetrafluoroethane",
        "chemicalFormula": "CClF2-CHF2",
        "molarMass_gMol": 136.48,
        "viscosity_muPa_s": "",
        "molarMass": 0.13648,
        "viscosity": 0,
        "label": "R124a - 1-Chloro-1,1,2,2-Tetrafluoroethane (CClF2-CHF2)"
    },
    {
        "id": "R125",
        "gasName": "Pentafluoroethane",
        "chemicalFormula": "CHF2-CF3",
        "molarMass_gMol": 120.02,
        "viscosity_muPa_s": 12.82,
        "molarMass": 0.12002,
        "viscosity": 0.00001282,
        "label": "R125 - Pentafluoroethane (CHF2-CF3)"
    },
    {
        "id": "R134",
        "gasName": "1,1,2,2-Tetrafluoroethane",
        "chemicalFormula": "CHF2-CHF2",
        "molarMass_gMol": 102.03,
        "viscosity_muPa_s": "",
        "molarMass": 0.10203,
        "viscosity": 0,
        "label": "R134 - 1,1,2,2-Tetrafluoroethane (CHF2-CHF2)"
    },
    {
        "id": "R134a",
        "gasName": "1,1,1,2-Tetrafluoroethane",
        "chemicalFormula": "CH2F-CF3",
        "molarMass_gMol": 102.03,
        "viscosity_muPa_s": 11.61,
        "molarMass": 0.10203,
        "viscosity": 0.00001161,
        "label": "R134a - 1,1,1,2-Tetrafluoroethane (CH2F-CF3)"
    },
    {
        "id": "R141b",
        "gasName": "1,1-Dichloro-1-Fluoroethane",
        "chemicalFormula": "CH3-CCl2F",
        "molarMass_gMol": 116.95,
        "viscosity_muPa_s": 9.08,
        "molarMass": 0.11695,
        "viscosity": 0.00000908,
        "label": "R141b - 1,1-Dichloro-1-Fluoroethane (CH3-CCl2F)"
    },
    {
        "id": "R142b",
        "gasName": "1-Chloro-1,1-Difluoroethane",
        "chemicalFormula": "CH3-CClF2",
        "molarMass_gMol": 100.49,
        "viscosity_muPa_s": 9.74,
        "molarMass": 0.10049,
        "viscosity": 0.00000974,
        "label": "R142b - 1-Chloro-1,1-Difluoroethane (CH3-CClF2)"
    },
    {
        "id": "R143",
        "gasName": "1,1,2-Trifluoroethane",
        "chemicalFormula": "CH2F-CHF2",
        "molarMass_gMol": 84.04,
        "viscosity_muPa_s": "",
        "molarMass": 0.08404,
        "viscosity": 0,
        "label": "R143 - 1,1,2-Trifluoroethane (CH2F-CHF2)"
    },
    {
        "id": "R143a",
        "gasName": "1,1,1-Trifluoroethane",
        "chemicalFormula": "CH3-CF3",
        "molarMass_gMol": 84.04,
        "viscosity_muPa_s": 10.95,
        "molarMass": 0.08404,
        "viscosity": 0.00001095,
        "label": "R143a - 1,1,1-Trifluoroethane (CH3-CF3)"
    },
    {
        "id": "R152a",
        "gasName": "1,1-Difluoroethane",
        "chemicalFormula": "CH3-CHF2",
        "molarMass_gMol": 66.05,
        "viscosity_muPa_s": 10.1,
        "molarMass": 0.06605,
        "viscosity": 0.0000101,
        "label": "R152a - 1,1-Difluoroethane (CH3-CHF2)"
    },
    {
        "id": "R160",
        "gasName": "Chloroethane",
        "chemicalFormula": "CH3CH2Cl",
        "molarMass_gMol": 64.51,
        "viscosity_muPa_s": "",
        "molarMass": 0.06451,
        "viscosity": 0,
        "label": "R160 - Chloroethane (CH3CH2Cl)"
    },
    {
        "id": "R161",
        "gasName": "Fluoroethane",
        "chemicalFormula": "CH3CH2F",
        "molarMass_gMol": 48.06,
        "viscosity_muPa_s": "",
        "molarMass": 0.04806,
        "viscosity": 0,
        "label": "R161 - Fluoroethane (CH3CH2F)"
    },
    {
        "id": "R170",
        "gasName": "Ethane",
        "chemicalFormula": "CH3-CH3",
        "molarMass_gMol": 30.07,
        "viscosity_muPa_s": 11.6,
        "molarMass": 0.03007,
        "viscosity": 0.0000116,
        "label": "R170 - Ethane (CH3-CH3)"
    },
    {
        "id": "R218",
        "gasName": "Octafluoropropane",
        "chemicalFormula": "CF3CF2CF3",
        "molarMass_gMol": 188.02,
        "viscosity_muPa_s": 12.3,
        "molarMass": 0.18802,
        "viscosity": 0.0000123,
        "label": "R218 - Octafluoropropane (CF3CF2CF3)"
    },
    {
        "id": "R227ca",
        "gasName": "",
        "chemicalFormula": "CF3CHFCF3",
        "molarMass_gMol": 170.03,
        "viscosity_muPa_s": "",
        "molarMass": 0.17003,
        "viscosity": 0,
        "label": "R227ca -  (CF3CHFCF3)"
    },
    {
        "id": "R227ea",
        "gasName": "1,1,1,2,3,3,3-Heptafluoropropane",
        "chemicalFormula": "CF3-CFH-CF3",
        "molarMass_gMol": 170.03,
        "viscosity_muPa_s": 11.38,
        "molarMass": 0.17003,
        "viscosity": 0.00001138,
        "label": "R227ea - 1,1,1,2,3,3,3-Heptafluoropropane (CF3-CFH-CF3)"
    },
    {
        "id": "R236ca",
        "gasName": "",
        "chemicalFormula": "CHF2CF2CHF2",
        "molarMass_gMol": 152.04,
        "viscosity_muPa_s": "",
        "molarMass": 0.15204,
        "viscosity": 0,
        "label": "R236ca -  (CHF2CF2CHF2)"
    },
    {
        "id": "R236cb",
        "gasName": "",
        "chemicalFormula": "CH2FCF2CF3",
        "molarMass_gMol": 152.04,
        "viscosity_muPa_s": "",
        "molarMass": 0.15204,
        "viscosity": 0,
        "label": "R236cb -  (CH2FCF2CF3)"
    },
    {
        "id": "R236ea",
        "gasName": "1,1,1,2,3,3-Hexafluoropropane",
        "chemicalFormula": "CHF2-CHF-CF3",
        "molarMass_gMol": 152.04,
        "viscosity_muPa_s": 10.76,
        "molarMass": 0.15204,
        "viscosity": 0.00001076,
        "label": "R236ea - 1,1,1,2,3,3-Hexafluoropropane (CHF2-CHF-CF3)"
    },
    {
        "id": "R236fa",
        "gasName": "1,1,1,3,3,3-Hexafluoropropane",
        "chemicalFormula": "CF3-CH2-CF3",
        "molarMass_gMol": 152.04,
        "viscosity_muPa_s": 10.78,
        "molarMass": 0.15204,
        "viscosity": 0.00001078,
        "label": "R236fa - 1,1,1,3,3,3-Hexafluoropropane (CF3-CH2-CF3)"
    },
    {
        "id": "R245ca",
        "gasName": "1,1,2,2,3-Pentafluoropropane",
        "chemicalFormula": "CH2F-CF2-CHF2",
        "molarMass_gMol": 134.05,
        "viscosity_muPa_s": 9.98,
        "molarMass": 0.13405,
        "viscosity": 0.00000998,
        "label": "R245ca - 1,1,2,2,3-Pentafluoropropane (CH2F-CF2-CHF2)"
    },
    {
        "id": "R245cb",
        "gasName": "",
        "chemicalFormula": "CH3-CF2-CF3",
        "molarMass_gMol": 134.05,
        "viscosity_muPa_s": "",
        "molarMass": 0.13405,
        "viscosity": 0,
        "label": "R245cb -  (CH3-CF2-CF3)"
    },
    {
        "id": "R245fa",
        "gasName": "1,1,1,3,3-Pentafluoropropane",
        "chemicalFormula": "CHF2-CH2-CF3",
        "molarMass_gMol": 134.05,
        "viscosity_muPa_s": 10.17,
        "molarMass": 0.13405,
        "viscosity": 0.00001017,
        "label": "R245fa - 1,1,1,3,3-Pentafluoropropane (CHF2-CH2-CF3)"
    },
    {
        "id": "R254cb",
        "gasName": "",
        "chemicalFormula": "CH3-CF2-CHF2",
        "molarMass_gMol": 116.06,
        "viscosity_muPa_s": "",
        "molarMass": 0.11606,
        "viscosity": 0,
        "label": "R254cb -  (CH3-CF2-CHF2)"
    },
    {
        "id": "R290",
        "gasName": "Propane",
        "chemicalFormula": "CH3-CH2-CH3",
        "molarMass_gMol": 44.1,
        "viscosity_muPa_s": 8.5,
        "molarMass": 0.0441,
        "viscosity": 0.0000085,
        "label": "R290 - Propane (CH3-CH2-CH3)"
    },
    {
        "id": "R356",
        "gasName": "1,1,1,4,4,4-Hexafluorobutane",
        "chemicalFormula": "CF3CH2CH2CF3",
        "molarMass_gMol": 166.07,
        "viscosity_muPa_s": "",
        "molarMass": 0.16607,
        "viscosity": 0,
        "label": "R356 - 1,1,1,4,4,4-Hexafluorobutane (CF3CH2CH2CF3)"
    },
    {
        "id": "R400",
        "gasName": "R400 <50/50>",
        "chemicalFormula": "50%R12+50%R114",
        "molarMass_gMol": 141.63,
        "viscosity_muPa_s": 11.21,
        "molarMass": 0.14163,
        "viscosity": 0.00001121,
        "label": "R400 - R400 <50/50> (50%R12+50%R114)"
    },
    {
        "id": "R400",
        "gasName": "R400 <60/40>",
        "chemicalFormula": "60%R12+40%R114",
        "molarMass_gMol": 136.94,
        "viscosity_muPa_s": 11.3,
        "molarMass": 0.13694,
        "viscosity": 0.0000113,
        "label": "R400 - R400 <60/40> (60%R12+40%R114)"
    },
    {
        "id": "R401A",
        "gasName": "MP39",
        "chemicalFormula": "53%R22+13%R152a+34%R124",
        "molarMass_gMol": 94.44,
        "viscosity_muPa_s": 11.94,
        "molarMass": 0.09444,
        "viscosity": 0.00001194,
        "label": "R401A - MP39 (53%R22+13%R152a+34%R124)"
    },
    {
        "id": "R401B",
        "gasName": "MP66",
        "chemicalFormula": "61%R22+11%R152a+28%R124",
        "molarMass_gMol": 92.84,
        "viscosity_muPa_s": 12.02,
        "molarMass": 0.09284,
        "viscosity": 0.00001202,
        "label": "R401B - MP66 (61%R22+11%R152a+28%R124)"
    },
    {
        "id": "R401C",
        "gasName": "MP52",
        "chemicalFormula": "33%R22+15%R152a+52%R124",
        "molarMass_gMol": 101.03,
        "viscosity_muPa_s": 11.75,
        "molarMass": 0.10103,
        "viscosity": 0.00001175,
        "label": "R401C - MP52 (33%R22+15%R152a+52%R124)"
    },
    {
        "id": "R402A",
        "gasName": "HP80",
        "chemicalFormula": "60%R125+2%R290+38%R22",
        "molarMass_gMol": 101.55,
        "viscosity_muPa_s": 12.69,
        "molarMass": 0.10155,
        "viscosity": 0.00001269,
        "label": "R402A - HP80 (60%R125+2%R290+38%R22)"
    },
    {
        "id": "R402B",
        "gasName": "HP81",
        "chemicalFormula": "38%R125+2%R290+60%R22",
        "molarMass_gMol": 94.71,
        "viscosity_muPa_s": 12.59,
        "molarMass": 0.09471,
        "viscosity": 0.00001259,
        "label": "R402B - HP81 (38%R125+2%R290+60%R22)"
    },
    {
        "id": "R403A",
        "gasName": "Isceon 69-S / Starton 69 / RX",
        "chemicalFormula": "5%R290+75%R22+20%R218",
        "molarMass_gMol": 91.99,
        "viscosity_muPa_s": 12.6,
        "molarMass": 0.09199,
        "viscosity": 0.0000126,
        "label": "R403A - Isceon 69-S / Starton 69 / RX (5%R290+75%R22+20%R218)"
    },
    {
        "id": "R403B",
        "gasName": "Isceon 69-L / RX1",
        "chemicalFormula": "5%R290+56%R22+39%R218",
        "molarMass_gMol": 103.26,
        "viscosity_muPa_s": 12.7,
        "molarMass": 0.10326,
        "viscosity": 0.0000127,
        "label": "R403B - Isceon 69-L / RX1 (5%R290+56%R22+39%R218)"
    },
    {
        "id": "R404A",
        "gasName": "HP62 / M55 / FX-70",
        "chemicalFormula": "44%R125+52%R143a+4%R134a",
        "molarMass_gMol": 97.6,
        "viscosity_muPa_s": 11.89,
        "molarMass": 0.0976,
        "viscosity": 0.00001189,
        "label": "R404A - HP62 / M55 / FX-70 (44%R125+52%R143a+4%R134a)"
    },
    {
        "id": "R405A",
        "gasName": "G2015",
        "chemicalFormula": "45%R22+7%R152a+5.5%R142b+42.5%RC318",
        "molarMass_gMol": 111.91,
        "viscosity_muPa_s": 12.18,
        "molarMass": 0.11191,
        "viscosity": 0.00001218,
        "label": "R405A - G2015 (45%R22+7%R152a+5.5%R142b+42.5%RC318)"
    },
    {
        "id": "R406A",
        "gasName": "GHG-X3",
        "chemicalFormula": "55%R22+4%R600a+41%R142b",
        "molarMass_gMol": 89.86,
        "viscosity_muPa_s": 11.05,
        "molarMass": 0.08986,
        "viscosity": 0.00001105,
        "label": "R406A - GHG-X3 (55%R22+4%R600a+41%R142b)"
    },
    {
        "id": "R407A",
        "gasName": "Klea 60",
        "chemicalFormula": "20%R32+40%R125+40%R134a",
        "molarMass_gMol": 90.11,
        "viscosity_muPa_s": 12.5,
        "molarMass": 0.09011,
        "viscosity": 0.0000125,
        "label": "R407A - Klea 60 (20%R32+40%R125+40%R134a)"
    },
    {
        "id": "R407B",
        "gasName": "Klea 61",
        "chemicalFormula": "10%R32+70%R125+20%R134a",
        "molarMass_gMol": 102.94,
        "viscosity_muPa_s": 12.72,
        "molarMass": 0.10294,
        "viscosity": 0.00001272,
        "label": "R407B - Klea 61 (10%R32+70%R125+20%R134a)"
    },
    {
        "id": "R407C",
        "gasName": "Klea 66 / SUVA 9000 / M95 / HX3",
        "chemicalFormula": "23%R32+25%R125+52%R134a",
        "molarMass_gMol": 86.2,
        "viscosity_muPa_s": 12.31,
        "molarMass": 0.0862,
        "viscosity": 0.00001231,
        "label": "R407C - Klea 66 / SUVA 9000 / M95 / HX3 (23%R32+25%R125+52%R134a)"
    },
    {
        "id": "R407D",
        "gasName": "",
        "chemicalFormula": "15%R32+15%R125+70%R134a",
        "molarMass_gMol": 90.96,
        "viscosity_muPa_s": 11.98,
        "molarMass": 0.09096,
        "viscosity": 0.00001198,
        "label": "R407D -  (15%R32+15%R125+70%R134a)"
    },
    {
        "id": "R407E",
        "gasName": "",
        "chemicalFormula": "25%R32+15%R125+60%R134a",
        "molarMass_gMol": 83.78,
        "viscosity_muPa_s": 12.19,
        "molarMass": 0.08378,
        "viscosity": 0.00001219,
        "label": "R407E -  (25%R32+15%R125+60%R134a)"
    },
    {
        "id": "R408A",
        "gasName": "FX-10",
        "chemicalFormula": "7%R125+46%R143a+47%R22",
        "molarMass_gMol": 87.01,
        "viscosity_muPa_s": 11.8,
        "molarMass": 0.08701,
        "viscosity": 0.0000118,
        "label": "R408A - FX-10 (7%R125+46%R143a+47%R22)"
    },
    {
        "id": "R409A",
        "gasName": "FX-56",
        "chemicalFormula": "60%R22+25%R124+15%142b",
        "molarMass_gMol": 97.43,
        "viscosity_muPa_s": 11.85,
        "molarMass": 0.09743,
        "viscosity": 0.00001185,
        "label": "R409A - FX-56 (60%R22+25%R124+15%142b)"
    },
    {
        "id": "R409B",
        "gasName": "FX-57",
        "chemicalFormula": "65%R22+25%R124+10%142b",
        "molarMass_gMol": 96.67,
        "viscosity_muPa_s": 12,
        "molarMass": 0.09667,
        "viscosity": 0.000012,
        "label": "R409B - FX-57 (65%R22+25%R124+10%142b)"
    },
    {
        "id": "R410A",
        "gasName": "AZ-20",
        "chemicalFormula": "50%R32+50%R125",
        "molarMass_gMol": 72.58,
        "viscosity_muPa_s": 13.17,
        "molarMass": 0.07258,
        "viscosity": 0.00001317,
        "label": "R410A - AZ-20 (50%R32+50%R125)"
    },
    {
        "id": "R410B",
        "gasName": "SUVA 9100",
        "chemicalFormula": "45%R32+55%R125",
        "molarMass_gMol": 75.57,
        "viscosity_muPa_s": 13.19,
        "molarMass": 0.07557,
        "viscosity": 0.00001319,
        "label": "R410B - SUVA 9100 (45%R32+55%R125)"
    },
    {
        "id": "R411A",
        "gasName": "G2018a",
        "chemicalFormula": "1.5%R1270+87.5%R22+11%R152a",
        "molarMass_gMol": 82.36,
        "viscosity_muPa_s": 12.09,
        "molarMass": 0.08236,
        "viscosity": 0.00001209,
        "label": "R411A - G2018a (1.5%R1270+87.5%R22+11%R152a)"
    },
    {
        "id": "R411B",
        "gasName": "G2018b",
        "chemicalFormula": "3%R1270+94%R22+3%R152a",
        "molarMass_gMol": 83.07,
        "viscosity_muPa_s": 12.23,
        "molarMass": 0.08307,
        "viscosity": 0.00001223,
        "label": "R411B - G2018b (3%R1270+94%R22+3%R152a)"
    },
    {
        "id": "R411C",
        "gasName": "G2018c",
        "chemicalFormula": "3%R1270+95.5%R22+1.5%R152a",
        "molarMass_gMol": 83.44,
        "viscosity_muPa_s": 12.3,
        "molarMass": 0.08344,
        "viscosity": 0.0000123,
        "label": "R411C - G2018c (3%R1270+95.5%R22+1.5%R152a)"
    },
    {
        "id": "R412A",
        "gasName": "Arcton TP5R",
        "chemicalFormula": "70%R22+5%R218+25%R142b",
        "molarMass_gMol": 92.17,
        "viscosity_muPa_s": 11.8,
        "molarMass": 0.09217,
        "viscosity": 0.0000118,
        "label": "R412A - Arcton TP5R (70%R22+5%R218+25%R142b)"
    },
    {
        "id": "R413A",
        "gasName": "Isceon 49 / RX2",
        "chemicalFormula": "9%R218+88%R134a+3%R600a",
        "molarMass_gMol": 103.95,
        "viscosity_muPa_s": 11.4,
        "molarMass": 0.10395,
        "viscosity": 0.0000114,
        "label": "R413A - Isceon 49 / RX2 (9%R218+88%R134a+3%R600a)"
    },
    {
        "id": "R414A",
        "gasName": "GHG-X4",
        "chemicalFormula": "51%R22+28.5%R124+4%R600a+16.5%R142b",
        "molarMass_gMol": 96.93,
        "viscosity_muPa_s": 11.5,
        "molarMass": 0.09693,
        "viscosity": 0.0000115,
        "label": "R414A - GHG-X4 (51%R22+28.5%R124+4%R600a+16.5%R142b)"
    },
    {
        "id": "R414B",
        "gasName": "",
        "chemicalFormula": "50%R22+39%R124+1.5%R600a+9.5%R142b",
        "molarMass_gMol": 101.59,
        "viscosity_muPa_s": 11.81,
        "molarMass": 0.10159,
        "viscosity": 0.00001181,
        "label": "R414B -  (50%R22+39%R124+1.5%R600a+9.5%R142b)"
    },
    {
        "id": "R415A",
        "gasName": "NARM-22",
        "chemicalFormula": "5%R23+80%R22+15%R152a",
        "molarMass_gMol": 81.72,
        "viscosity_muPa_s": 12.2,
        "molarMass": 0.08172,
        "viscosity": 0.0000122,
        "label": "R415A - NARM-22 (5%R23+80%R22+15%R152a)"
    },
    {
        "id": "R416A",
        "gasName": "FR-12 / HC-600",
        "chemicalFormula": "59%R134a+39.5%R124+1.5%R600",
        "molarMass_gMol": 111.92,
        "viscosity_muPa_s": 11.3,
        "molarMass": 0.11192,
        "viscosity": 0.0000113,
        "label": "R416A - FR-12 / HC-600 (59%R134a+39.5%R124+1.5%R600)"
    },
    {
        "id": "R500",
        "gasName": "",
        "chemicalFormula": "73.8%R12+26.2%R152a",
        "molarMass_gMol": 99.3,
        "viscosity_muPa_s": 11.41,
        "molarMass": 0.0993,
        "viscosity": 0.00001141,
        "label": "R500 -  (73.8%R12+26.2%R152a)"
    },
    {
        "id": "R501",
        "gasName": "",
        "chemicalFormula": "75%R22+25%R12",
        "molarMass_gMol": 93.1,
        "viscosity_muPa_s": 12.3,
        "molarMass": 0.0931,
        "viscosity": 0.0000123,
        "label": "R501 -  (75%R22+25%R12)"
    },
    {
        "id": "R502",
        "gasName": "",
        "chemicalFormula": "48.8%R22+51.2%R115",
        "molarMass_gMol": 111.63,
        "viscosity_muPa_s": 12.66,
        "molarMass": 0.11163,
        "viscosity": 0.00001266,
        "label": "R502 -  (48.8%R22+51.2%R115)"
    },
    {
        "id": "R503",
        "gasName": "",
        "chemicalFormula": "40.1%R23+59.9%R13",
        "molarMass_gMol": 87.25,
        "viscosity_muPa_s": 13.94,
        "molarMass": 0.08725,
        "viscosity": 0.00001394,
        "label": "R503 -  (40.1%R23+59.9%R13)"
    },
    {
        "id": "R504",
        "gasName": "",
        "chemicalFormula": "48.2%R32+51.8%R115",
        "molarMass_gMol": 79.25,
        "viscosity_muPa_s": 13.33,
        "molarMass": 0.07925,
        "viscosity": 0.00001333,
        "label": "R504 -  (48.2%R32+51.8%R115)"
    },
    {
        "id": "R505",
        "gasName": "",
        "chemicalFormula": "78%R12+22%R31",
        "molarMass_gMol": 103.48,
        "viscosity_muPa_s": "",
        "molarMass": 0.10348,
        "viscosity": 0,
        "label": "R505 -  (78%R12+22%R31)"
    },
    {
        "id": "R506",
        "gasName": "",
        "chemicalFormula": "55.1%R31+44.9%R114",
        "molarMass_gMol": 93.69,
        "viscosity_muPa_s": "",
        "molarMass": 0.09369,
        "viscosity": 0,
        "label": "R506 -  (55.1%R31+44.9%R114)"
    },
    {
        "id": "R507A",
        "gasName": "AZ-50 / M57",
        "chemicalFormula": "50%R125+50%R143a",
        "molarMass_gMol": 98.86,
        "viscosity_muPa_s": 11.96,
        "molarMass": 0.09886,
        "viscosity": 0.00001196,
        "label": "R507A - AZ-50 / M57 (50%R125+50%R143a)"
    },
    {
        "id": "R508A",
        "gasName": "Klea 5R3 / Arcton TP5R3",
        "chemicalFormula": "39%R23+61%R116",
        "molarMass_gMol": 100.1,
        "viscosity_muPa_s": 14.32,
        "molarMass": 0.1001,
        "viscosity": 0.00001432,
        "label": "R508A - Klea 5R3 / Arcton TP5R3 (39%R23+61%R116)"
    },
    {
        "id": "R508B",
        "gasName": "SUVA 95",
        "chemicalFormula": "46%R23+54%R116",
        "molarMass_gMol": 95.39,
        "viscosity_muPa_s": 14.32,
        "molarMass": 0.09539,
        "viscosity": 0.00001432,
        "label": "R508B - SUVA 95 (46%R23+54%R116)"
    },
    {
        "id": "R509A",
        "gasName": "Arcton TP5R2",
        "chemicalFormula": "44%R22+56%R218",
        "molarMass_gMol": 123.96,
        "viscosity_muPa_s": 12.9,
        "molarMass": 0.12396,
        "viscosity": 0.0000129,
        "label": "R509A - Arcton TP5R2 (44%R22+56%R218)"
    },
    {
        "id": "R600",
        "gasName": "Butane",
        "chemicalFormula": "CH3-CH2-CH2-CH3",
        "molarMass_gMol": 58.12,
        "viscosity_muPa_s": 7.39,
        "molarMass": 0.05812,
        "viscosity": 0.00000739,
        "label": "R600 - Butane (CH3-CH2-CH2-CH3)"
    },
    {
        "id": "R600a",
        "gasName": "Isobutane",
        "chemicalFormula": "CH(CH3)2-CH3",
        "molarMass_gMol": 58.12,
        "viscosity_muPa_s": 7.4,
        "molarMass": 0.05812,
        "viscosity": 0.0000074,
        "label": "R600a - Isobutane (CH(CH3)2-CH3)"
    },
    {
        "id": "R601",
        "gasName": "Pentane",
        "chemicalFormula": "CH3-CH2-CH2-CH2-CH3",
        "molarMass_gMol": 72.15,
        "viscosity_muPa_s": 6.7,
        "molarMass": 0.07215,
        "viscosity": 0.0000067,
        "label": "R601 - Pentane (CH3-CH2-CH2-CH2-CH3)"
    },
    {
        "id": "R601a",
        "gasName": "Isopentane",
        "chemicalFormula": "(CH3)2CH-CH2-CH3",
        "molarMass_gMol": 72.15,
        "viscosity_muPa_s": 6.6,
        "molarMass": 0.07215,
        "viscosity": 0.0000066,
        "label": "R601a - Isopentane ((CH3)2CH-CH2-CH3)"
    },
    {
        "id": "R601b",
        "gasName": "Neopentane",
        "chemicalFormula": "(CH3)4C",
        "molarMass_gMol": 72.15,
        "viscosity_muPa_s": "",
        "molarMass": 0.07215,
        "viscosity": 0,
        "label": "R601b - Neopentane ((CH3)4C)"
    },
    {
        "id": "R601c",
        "gasName": "Cyclopentane",
        "chemicalFormula": "C5H10",
        "molarMass_gMol": 70.13,
        "viscosity_muPa_s": "",
        "molarMass": 0.07013,
        "viscosity": 0,
        "label": "R601c - Cyclopentane (C5H10)"
    },
    {
        "id": "R610",
        "gasName": "Ethylether",
        "chemicalFormula": "CH3-CH2-O-CH2-CH3",
        "molarMass_gMol": 74.12,
        "viscosity_muPa_s": "",
        "molarMass": 0.07412,
        "viscosity": 0,
        "label": "R610 - Ethylether (CH3-CH2-O-CH2-CH3)"
    },
    {
        "id": "R611",
        "gasName": "Methylformate",
        "chemicalFormula": "HCOOCH3",
        "molarMass_gMol": 60.05,
        "viscosity_muPa_s": "",
        "molarMass": 0.06005,
        "viscosity": 0,
        "label": "R611 - Methylformate (HCOOCH3)"
    },
    {
        "id": "R630",
        "gasName": "Methylamine",
        "chemicalFormula": "CH3(NH2)",
        "molarMass_gMol": 31.06,
        "viscosity_muPa_s": "",
        "molarMass": 0.03106,
        "viscosity": 0,
        "label": "R630 - Methylamine (CH3(NH2))"
    },
    {
        "id": "R631",
        "gasName": "Ethylamine",
        "chemicalFormula": "CH3-CH2(NH2)",
        "molarMass_gMol": 45.1,
        "viscosity_muPa_s": "",
        "molarMass": 0.0451,
        "viscosity": 0,
        "label": "R631 - Ethylamine (CH3-CH2(NH2))"
    },
    {
        "id": "R702",
        "gasName": "Hydrogen",
        "chemicalFormula": "H2",
        "molarMass_gMol": 2.02,
        "viscosity_muPa_s": 8.8,
        "molarMass": 0.00202,
        "viscosity": 0.0000088,
        "label": "R702 - Hydrogen (H2)"
    },
    {
        "id": "R704",
        "gasName": "Helium",
        "chemicalFormula": "He",
        "molarMass_gMol": 4,
        "viscosity_muPa_s": 19.41,
        "molarMass": 0.004,
        "viscosity": 0.00001941,
        "label": "R704 - Helium (He)"
    },
    {
        "id": "R717",
        "gasName": "Ammonia",
        "chemicalFormula": "NH3",
        "molarMass_gMol": 17.03,
        "viscosity_muPa_s": 9.91,
        "molarMass": 0.01703,
        "viscosity": 0.00000991,
        "label": "R717 - Ammonia (NH3)"
    },
    {
        "id": "R718",
        "gasName": "Water",
        "chemicalFormula": "H2O",
        "molarMass_gMol": 18.02,
        "viscosity_muPa_s": 9,
        "molarMass": 0.01802,
        "viscosity": 0.000009,
        "label": "R718 - Water (H2O)"
    },
    {
        "id": "R720",
        "gasName": "Neon",
        "chemicalFormula": "Ne",
        "molarMass_gMol": 20.18,
        "viscosity_muPa_s": 31.11,
        "molarMass": 0.02018,
        "viscosity": 0.00003111,
        "label": "R720 - Neon (Ne)"
    },
    {
        "id": "R728",
        "gasName": "Nitrogen",
        "chemicalFormula": "N2",
        "molarMass_gMol": 28.01,
        "viscosity_muPa_s": 17.48,
        "molarMass": 0.02801,
        "viscosity": 0.00001748,
        "label": "R728 - Nitrogen (N2)"
    },
    {
        "id": "R732",
        "gasName": "Oxygen",
        "chemicalFormula": "O2",
        "molarMass_gMol": 32,
        "viscosity_muPa_s": 20.24,
        "molarMass": 0.032,
        "viscosity": 0.00002024,
        "label": "R732 - Oxygen (O2)"
    },
    {
        "id": "R740",
        "gasName": "Argon",
        "chemicalFormula": "Ar",
        "molarMass_gMol": 39.95,
        "viscosity_muPa_s": 22.11,
        "molarMass": 0.03995,
        "viscosity": 0.00002211,
        "label": "R740 - Argon (Ar)"
    },
    {
        "id": "R744",
        "gasName": "Carbon dioxide",
        "chemicalFormula": "CO2",
        "molarMass_gMol": 44.01,
        "viscosity_muPa_s": 14.63,
        "molarMass": 0.04401,
        "viscosity": 0.00001463,
        "label": "R744 - Carbon dioxide (CO2)"
    },
    {
        "id": "R764",
        "gasName": "Sulfur dioxide",
        "chemicalFormula": "SO2",
        "molarMass_gMol": 64.06,
        "viscosity_muPa_s": 12.52,
        "molarMass": 0.06406,
        "viscosity": 0.00001252,
        "label": "R764 - Sulfur dioxide (SO2)"
    },
    {
        "id": "R784",
        "gasName": "Krypton",
        "chemicalFormula": "Kr",
        "molarMass_gMol": 83.8,
        "viscosity_muPa_s": 25.04,
        "molarMass": 0.0838,
        "viscosity": 0.00002504,
        "label": "R784 - Krypton (Kr)"
    },
    {
        "id": "R7131",
        "gasName": "Xenon",
        "chemicalFormula": "Xe",
        "molarMass_gMol": 131.29,
        "viscosity_muPa_s": 22.6,
        "molarMass": 0.13129,
        "viscosity": 0.0000226,
        "label": "R7131 - Xenon (Xe)"
    },
    {
        "id": "R7146",
        "gasName": "Sulfur hexafluoride",
        "chemicalFormula": "SF6",
        "molarMass_gMol": 146.05,
        "viscosity_muPa_s": 15,
        "molarMass": 0.14605,
        "viscosity": 0.000015,
        "label": "R7146 - Sulfur hexafluoride (SF6)"
    },
    {
        "id": "R1130",
        "gasName": "Dielene",
        "chemicalFormula": "CHCl=CHCl",
        "molarMass_gMol": 96.94,
        "viscosity_muPa_s": "",
        "molarMass": 0.09694,
        "viscosity": 0,
        "label": "R1130 - Dielene (CHCl=CHCl)"
    },
    {
        "id": "R1150",
        "gasName": "Ethylene",
        "chemicalFormula": "CH2=CH2",
        "molarMass_gMol": 28.05,
        "viscosity_muPa_s": 10.08,
        "molarMass": 0.02805,
        "viscosity": 0.00001008,
        "label": "R1150 - Ethylene (CH2=CH2)"
    },
    {
        "id": "R1270",
        "gasName": "Propene",
        "chemicalFormula": "CH3CH=CH2",
        "molarMass_gMol": 42.08,
        "viscosity_muPa_s": 7.7,
        "molarMass": 0.04208,
        "viscosity": 0.0000077,
        "label": "R1270 - Propene (CH3CH=CH2)"
    },
    {
        "id": "C318",
        "gasName": "Octafluorocyclobutane (RC318)",
        "chemicalFormula": "-CF2-CF2-CF2-CF2-",
        "molarMass_gMol": 200.03,
        "viscosity_muPa_s": 11.26,
        "molarMass": 0.20003,
        "viscosity": 0.00001126,
        "label": "C318 - Octafluorocyclobutane (RC318) (-CF2-CF2-CF2-CF2-)"
    },
    {
        "id": "C270",
        "gasName": "Cyclopropane (RC270)",
        "chemicalFormula": "-CH2-CH2-CH2-",
        "molarMass_gMol": 42.08,
        "viscosity_muPa_s": "",
        "molarMass": 0.04208,
        "viscosity": 0,
        "label": "C270 - Cyclopropane (RC270) (-CH2-CH2-CH2-)"
    },
    {
        "id": "E318",
        "gasName": "",
        "chemicalFormula": "CF2-CF2CF2OCF2",
        "molarMass_gMol": 216.03,
        "viscosity_muPa_s": "",
        "molarMass": 0.21603,
        "viscosity": 0,
        "label": "E318 -  (CF2-CF2CF2OCF2)"
    },
    {
        "id": "E125",
        "gasName": "",
        "chemicalFormula": "CHF2-O-CF3",
        "molarMass_gMol": 136.02,
        "viscosity_muPa_s": "",
        "molarMass": 0.13602,
        "viscosity": 0,
        "label": "E125 -  (CHF2-O-CF3)"
    },
    {
        "id": "E134",
        "gasName": "Difluoromethoxy-Difluoromethane",
        "chemicalFormula": "CHF2-O-CHF2",
        "molarMass_gMol": 118.03,
        "viscosity_muPa_s": 11,
        "molarMass": 0.11803,
        "viscosity": 0.000011,
        "label": "E134 - Difluoromethoxy-Difluoromethane (CHF2-O-CHF2)"
    },
    {
        "id": "E143",
        "gasName": "Trifluoroethanol",
        "chemicalFormula": "CH2FOCHF2",
        "molarMass_gMol": 100.04,
        "viscosity_muPa_s": "",
        "molarMass": 0.10004,
        "viscosity": 0,
        "label": "E143 - Trifluoroethanol (CH2FOCHF2)"
    },
    {
        "id": "E152",
        "gasName": "Fluoroethanol",
        "chemicalFormula": "CH3-O-CH2F",
        "molarMass_gMol": 64.06,
        "viscosity_muPa_s": "",
        "molarMass": 0.06406,
        "viscosity": 0,
        "label": "E152 - Fluoroethanol (CH3-O-CH2F)"
    },
    {
        "id": "E170",
        "gasName": "Dimethylether",
        "chemicalFormula": "CH3-0-CH3",
        "molarMass_gMol": 46.07,
        "viscosity_muPa_s": "",
        "molarMass": 0.04607,
        "viscosity": 0,
        "label": "E170 - Dimethylether (CH3-0-CH3)"
    },
    {
        "id": "E227ca2",
        "gasName": "",
        "chemicalFormula": "CHF2-CF2-O-CF3",
        "molarMass_gMol": 186.03,
        "viscosity_muPa_s": "",
        "molarMass": 0.18603,
        "viscosity": 0,
        "label": "E227ca2 -  (CHF2-CF2-O-CF3)"
    },
    {
        "id": "E245cb2",
        "gasName": "Pentafluoropropanol",
        "chemicalFormula": "CH3-CF2-O-CF3",
        "molarMass_gMol": 150.05,
        "viscosity_muPa_s": "",
        "molarMass": 0.15005,
        "viscosity": 0,
        "label": "E245cb2 - Pentafluoropropanol (CH3-CF2-O-CF3)"
    },
    {
        "id": "E347",
        "gasName": "Heptafluorobutanol",
        "chemicalFormula": "CF3-CH2-O-CF2-CHF2",
        "molarMass_gMol": 200.05,
        "viscosity_muPa_s": "",
        "molarMass": 0.20005,
        "viscosity": 0,
        "label": "E347 - Heptafluorobutanol (CF3-CH2-O-CF2-CHF2)"
    }
]
