import {TFunction} from "i18next";
import {unitConfig} from "../../config/unitConfig";
import {gasConfig} from "../../config/gasConfig";
import {Unit} from "../../types";

export const getFormAttributes = (
    formType: "conductancePipe" | "conductanceOrifice" | "effectiveSuctionSpeed" | "evacuation" | "desorption" | "gasFlowPressureSuctionSpeed",
    t: TFunction
): any[] => {
    if (formType === "conductancePipe") {
        return [
            {
                id: "length",
                label: t("Length"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "length"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "diameter",
                label: t("Diameter"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "length"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "mediumPressure",
                label: t("Medium pressure"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            // empty filler to arrange the Cols correctly --> can't be done with offset
            {
                width: {
                    xs: 0,
                    md: 12
                },
            },
            {
                id: "gasSpecies",
                label: t("Gas species"),
                type: "string",
                options: gasConfig,
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "gasTemperature",
                label: t("Gas temperature"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "temperature"),
                width: {
                    xs: 24,
                    md: 12
                },
                defaultValue: 293
            },
        ]
    }

    if (formType === "conductanceOrifice") {
        return [
            {
                id: "diameter",
                label: t("Diameter"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "length"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            // empty filler to arrange the Cols correctly --> can't be done with offset
            {
                width: {
                    xs: 0,
                    md: 12
                },
            },
            {
                id: "gasSpecies",
                label: t("Gas species"),
                type: "string",
                options: gasConfig,
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "gasTemperature",
                label: t("Gas temperature"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "temperature"),
                width: {
                    xs: 24,
                    md: 12
                },
                defaultValue: 293
            },
        ]
    }

    if (formType === "effectiveSuctionSpeed") {
        return [
            {
                id: "suctionSpeed",
                label: t("Suction speed pump"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "conductance",
                label: t("Conductance"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed"),
                width: {
                    xs: 24,
                    md: 12
                }
            }
        ]
    }

    if (formType === "evacuation") {
        return [
            {
                id: "volume",
                label: t("Volume"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "volume"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "startingPressure",
                label: t("Starting pressure"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "targetPressure",
                label: t("Target pressure"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                width: {
                    xs: 24,
                    md: 12
                }
            }
        ]
    }

    if (formType === "desorption") {
        return [
            {
                id: "desorptionRate",
                label: t("Desorption rate"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "desorptionRate"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "surfaceSize",
                label: t("Internal surface size"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "surface"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "suctionSpeed",
                label: t("Suction speed"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
            {
                id: "pressure",
                label: t("Working pressure"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
                width: {
                    xs: 24,
                    md: 12
                }
            },
        ]
    }

    if (formType === "gasFlowPressureSuctionSpeed") {
        return [
            {
                id: "gasFlow",
                label: t("Gas flow"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "gasFlow"),
            },
            {
                id: "pressure",
                label: t("Pressure"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "pressure"),
            },
            {
                id: "suctionSpeed",
                label: t("Suction speed"),
                type: "number",
                units: unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed"),
            }
        ]
    }

    return [];
}
