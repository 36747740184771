import _ from "lodash";
import {getCurrentEnv} from "../utils/getCurrentEnv";

export const getExternalConnection = (key: string) => {
    const env = getCurrentEnv();

    return _.get(externalConnections, `${env}.${key}`, null);
}

export const externalConnections = {
    testing: {
        ecommerceWebportal: "https://www.dev.customerportal.pfeiffer-vacuum.com/"
    },
    staging: {
        ecommerceWebportal: "https://www.uat.customerportal.pfeiffer-vacuum.com/"
    },
    production: {
        ecommerceWebportal: "https://www.pfeiffer-vacuum.com/"
    }
}
