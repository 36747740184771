import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {Col, Form, Row, Select} from "antd";
import DesorptionSlider from "../DesorptionSlider";
import {generateSliderConfig} from "../../utils/generateSliderConfig";
import {defaultDesorptionUnit} from "../../config/defaultValues";
import {getFormAttributes} from "./getFormAttributes";
import {useTranslation} from "react-i18next";
import NumberInput from "../NumberInput";
import {Unit} from "../../types";
import DesorptionRateSvgSmall from "../Svg/DesorptionRateSvg/small";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {unitConfig} from "../../config/unitConfig";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import "./index.css";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    onBackToHome: () => void;
}

interface ResultType {
    value: number | undefined,
    unit: string | undefined
}

const DesorptionCalculationForm = ({onBackToHome}: Props) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();

    const outgassingRateUnits = unitConfig.filter((unit: Unit) => unit.category === "gasFlow");
    const [outgassingRate, setOutgassingRate] = useState<ResultType>({value: undefined, unit: outgassingRateUnits[0].id});
    const resultUnits = unitConfig.filter((unit: Unit) => unit.category === "time");
    const [result, setResult] = useState<ResultType>({value: undefined, unit: resultUnits[0].id})
    const [calculationDone, setCalculationDone] = useState(false);

    const attributes = getFormAttributes("desorption", t)
    const sliderConfig = generateSliderConfig(defaultDesorptionUnit);
    const [sliderDesorptionRate, setSliderDesorptionRate] = useState(sliderConfig.defaultMark);

    let initialValues = {}
    attributes.forEach((attribute) => {
        if (attribute.units && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: null,
                [`${attribute.id}_unit`]: attribute.units[0].id
            })
        }
    })
    initialValues = {
        ...initialValues,
        ["desorptionRate"]: sliderConfig.defaultMark,
    }

    useEffect(() => {
        form.setFieldsValue({
            desorptionRate: sliderDesorptionRate
        })
    }, [sliderDesorptionRate])

    const calculateResult = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const q = convertUnitToSi(parseLocalizedFloat(_.get(values, 'desorptionRate')), _.get(values, 'desorptionRate_unit'));
                const A = convertUnitToSi(parseLocalizedFloat(_.get(values, 'surfaceSize')), _.get(values, 'surfaceSize_unit'));
                const S = convertUnitToSi(parseLocalizedFloat(_.get(values, 'suctionSpeed')),_.get(values, 'suctionSpeed_unit'));
                const p = convertUnitToSi(parseLocalizedFloat(_.get(values, 'pressure')), _.get(values, 'pressure_unit'));

                // TODO: Ist t(0) wirklich 1h oder variabel?
                const t0 = 3600

                // additionally we display the integral outgassing rate Q (Q = q * A)
                if (outgassingRate.unit && q && A) {
                    let calculatedOutgassingRate = q * A;
                    calculatedOutgassingRate = convertUnitFromSi(calculatedOutgassingRate, outgassingRate.unit)
                    calculatedOutgassingRate = roundNumber(calculatedOutgassingRate)
                    setOutgassingRate({...outgassingRate, value: calculatedOutgassingRate})
                }

                if (result.unit && q && A && S && p) {
                    let calculatedResult = ((q * A * t0) / (S * p));
                    calculatedResult = convertUnitFromSi(calculatedResult, result.unit)
                    calculatedResult = roundNumber(calculatedResult)
                    setResult({...result, value: calculatedResult})
                }
                setCalculationDone(true);
            });
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"form-border-bottom"}
                layout={"vertical"}
                form={form}
                initialValues={initialValues}
                onValuesChange={(values) => {
                    if (values.desorptionRate) {
                        setSliderDesorptionRate(values.desorptionRate)
                    }
                }}
            >
                <div style={{paddingLeft: '6px', paddingRight: '6px'}}>
                    <DesorptionRateSvgSmall/>
                </div>
                <div style={{color: '#525252', paddingLeft: '6px', paddingTop: '4px'}}>
                    <span style={{fontSize: '14px'}}>
                        {t("Select desorption rate (after 1 h), x")}:
                    </span>
                </div>
                <DesorptionSlider
                    value={sliderDesorptionRate}
                    onChange={(newDesorptionRate: any) => {
                        if (newDesorptionRate < sliderConfig.minExponential) {
                            setSliderDesorptionRate(sliderConfig.minExponential);
                        } else if (newDesorptionRate > sliderConfig.maxExponential) {
                            setSliderDesorptionRate(sliderConfig.maxExponential);
                        } else {
                            setSliderDesorptionRate(newDesorptionRate.toExponential(0));
                        }
                    }}
                    config={sliderConfig}
                />
                <Row gutter={16} className={"pt-16"}>
                    {
                        attributes.map(attribute => {
                            return <Col
                                key={attribute.id}
                                xs={{span: attribute.width.xs}}
                                md={{span: attribute.width.md}}
                            >
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item name={`${attribute.id}`} label={attribute.label} required={true} rules={[{ required: true }]}>
                                            <NumberInput />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name={`${attribute.id}_unit`} label={" "} required={true}>
                                            <Select tabIndex={-1}>
                                                {attribute.units.map((unit: Unit) => {
                                                    return <Select.Option
                                                        value={unit.id}
                                                        key={unit.value}
                                                    >
                                                        {unit.display}
                                                    </Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        })
                    }
                </Row>
            </Form>
            <div className={"result-row"}>
                <Row gutter={16} align={"middle"}>
                    <Col xs={24} md={12}>
                        <span className={"font-bold"}>{t("Integral outgassing rate")}:</span>
                    </Col>
                    <Col xs={12} md={6}>
                        <NumberInput className={calculationDone ? "success-border" : ""} disabled value={outgassingRate.value} exponentialbreakpoint={0.01}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Select
                            className={"full-width"}
                            value={outgassingRate.unit}
                            onChange={(value => setOutgassingRate({...outgassingRate, unit: value}))}
                        >
                            {
                                outgassingRateUnits.map((unit: Unit) => {
                                    return <Select.Option value={unit.id} key={unit.value}>
                                        {unit.display}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                <Row gutter={16} align={"middle"} className={"mt-16"}>
                    <Col xs={24} md={12}>
                        <span className={"font-bold"}>{t("Calculated additional time")}:</span>
                    </Col>
                    <Col xs={12} md={6}>
                        <NumberInput className={calculationDone ? "success-border" : ""} disabled value={result.value} exponentialbreakpoint={0.01}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Select
                            className={"full-width"}
                            value={result.unit}
                            onChange={(value => setResult({...result, unit: value}))}
                        >
                            {
                                resultUnits.map((unit: Unit) => {
                                    return <Select.Option value={unit.id} key={unit.value}>
                                        {unit.display}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
            </div>
            <SimpleCalculationActionButtons
                onBack={onBackToHome}
                onReset={() => {
                    setCalculationDone(false)
                    setResult({...result, value: undefined})
                    form.resetFields()
                }}
                onCalculate={() => calculateResult()}
            />
        </div>
    );
}

export default DesorptionCalculationForm;
