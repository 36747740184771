import React from 'react';
import {Col, Grid, Row} from "antd";
import {useTranslation} from "react-i18next";
import UnitConversionForm from "../SimpleCalculationUnitConversionForm";
import {Unit} from "../../types";
import {unitConfig} from "../../config/unitConfig";
import {Button} from "@dreebit/pv-components";

interface Props {
    onBackToHome: () => void
}

const SimpleCalculationUnitConversionFormWrapper = ({onBackToHome}: Props) => {

    const {t} = useTranslation();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    return (
        <>
            <div className={'pt-40'}>
                <div>
                    <h2 className={'font-bold'}>{t('Vacuum Calculator')}</h2>
                    <h3>{t('Perform a simple vacuum calculation')}</h3>
                </div>
                <div className={'mt-32'}>
                    <h4 className={'font-bold'}>{t('Unit Conversion')}</h4>
                    <Row gutter={screens.md ? 32 : 0}>
                        <Col span={screens.md ? 8 : 24}>
                            <UnitConversionForm
                                label={t('Pressure')}
                                units={unitConfig.filter((unit: Unit) => unit.category === "pressure")}
                            />
                        </Col>
                        <Col span={screens.md ? 8 : 24}>
                            <UnitConversionForm
                                label={t('Suction speed')}
                                units={unitConfig.filter((unit: Unit) => unit.category === "suctionSpeed")}
                            />
                        </Col>
                        <Col span={screens.md ? 8 : 24}>
                            <UnitConversionForm
                                label={t('Gas flow / leak rate')}
                                units={unitConfig.filter((unit: Unit) => unit.category === "leakRate")}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Button
                        type={"ghost"}
                        icon={"arrow_back"}
                        iconPosition={"left"}
                        onClick={onBackToHome}
                    >
                        {t('Back')}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SimpleCalculationUnitConversionFormWrapper;
