import {defaultDesorptionAndLeakRates, defaultDesorptionRate, defaultLeakRate} from "../config/defaultValues";
import {convertPressure} from "./unitConverter";

export const getStandardDesoprtionAndLeakRate = (pressure?: {value: number, unit: "mbar" | "Torr" | "hpa" | "Pa"}) => {
    if (!pressure) {
        return {
            desorptionRate: defaultDesorptionRate,
            leakRate: defaultLeakRate
        }
    } else {
        const matchingRates = defaultDesorptionAndLeakRates.find(x => convertPressure(pressure.value, "mbar", pressure.unit) <= x.pressureBreakpoint)

        if (matchingRates) {
            return {
                desorptionRate: matchingRates.desorptionRate,
                leakRate: matchingRates.leakRate
            }
        } else {
            return {
                desorptionRate: defaultDesorptionRate,
                leakRate: defaultLeakRate
            }
        }
    }
}
