import {useTranslation} from "react-i18next";

export interface CalculationValue {
    key: string
    value: any
    unit?: string
}

export interface IDesorptionFormData {
    desorptionRate?: any,
    desorptionRateUnit?: any,
    desorptionSurfaceName?: any,
    desorptionSurfaceSize?: any,
    desorptionSurfaceSizeUnit?: any
}

export interface IDesorptionSchemaData {
    desorptionRate?: {
        unit: any,
        value: any
    },
    desorptionSurfaceName?: {
        value: any
    },
    desorptionSurfaceSize?: {
        unit: any,
        value: any
    }
}

export const convertToSchemaValues = (formData: IDesorptionFormData[]): IDesorptionSchemaData[] => {
    return formData.map((formItem: IDesorptionFormData) => {
        return {
            desorptionRate: {
                unit: formItem.desorptionRateUnit,
                value: parseFloat(formItem.desorptionRate)
            },
            desorptionSurfaceName: {
                value: formItem.desorptionSurfaceName
            },
            desorptionSurfaceSize: {
                unit: formItem.desorptionSurfaceSizeUnit,
                value: parseFloat(formItem.desorptionSurfaceSize)
            }
        }
    })
}

export const convertToFormValues = (schemaData: any, t?: any): IDesorptionFormData[] => {

    return schemaData.map((schemaItem: IDesorptionSchemaData) => {
        return {
            desorptionRate: schemaItem.desorptionRate?.value,
            desorptionRateUnit: schemaItem.desorptionRate?.unit,
            desorptionSurfaceName: schemaItem.desorptionSurfaceName?.value || (t ? t('Chamber surface') : 'Chamber surface'),
            desorptionSurfaceSize: schemaItem.desorptionSurfaceSize?.value,
            desorptionSurfaceSizeUnit: schemaItem.desorptionSurfaceSize?.unit
        }
    })
}
