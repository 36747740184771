import {Calculation} from "../types";

export const calculationConfig: Calculation[] = [
    {
        id: "pumpFinder",
        title: "vc_home_vacuumPumpSolution_title",
        subtitle: "vc_home_vacuumPumpSolution_subtitle",
        subCalculations: [
            {
                id: "chamberEvacuation",
                title: "vc_home_chamberEvacuation_title",
                subtitle: "vc_home_chamberEvacuation_subtitle"
            },
            {
                id: "constantFlow",
                title: "vc_home_constantFlow_title",
                subtitle: "vc_home_constantFlow_subtitle",
                disabled: true
            }
        ]
    },
    {
        id: "fixedPumpSystem",
        title: "vc_home_pumpCurve_title",
        subtitle: "vc_home_pumpCurve_subtitle",
        loginNeeded: true,
        subCalculations: [
            {
                id: "chamberEvacuation",
                title: "vc_home_chamberEvacuation_title",
                subtitle: "vc_home_chamberEvacuation_subtitle"
            },
            {
                id: "constantFlow",
                title: "vc_home_constantFlow_title",
                subtitle: "vc_home_constantFlow_subtitle",
                disabled: true
            }
        ]
    },
    {
        id: "simpleCalculation",
        title: "vc_home_simpleCalculation_title",
        subtitle: "vc_home_simpleCalculation_subtitle",
        subCalculations: [
            {
                id: "vacuumCalculation",
                title: "vc_home_vacuumCalculation_title",
                subtitle: "vc_home_vacuumCalculation_subtitle"
            },
            {
                id: "chamberCalculation",
                title: "vc_home_chamberCalculation_title",
                subtitle: "vc_home_chamberCalculation_subtitle"
            },
            {
                id: "leakDetectionCalculation",
                title: "vc_home_leakDetection_title",
                subtitle: "vc_home_leakDetection_subtitle"
            },
            {
                id: "unitConversion",
                title: "vc_home_unitConversion_title",
                subtitle: "vc_home_unitConversion_subtitle"
            }
        ]
    }
]
