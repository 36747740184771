import React, {useState} from 'react';
import _ from "lodash";
import {Button} from "@dreebit/pv-components"
import {Radio, Space} from 'antd';
import {getSolutionTitle} from "../../utils/getSolutionTitle";
import {useTranslation} from "react-i18next";

interface Props {
    solutions: any[],
    onAddCalculation: (calculationId?: string) => void
}

const AddCollectionCalculationForm = ({solutions, onAddCalculation}: Props) => {

    const {t} = useTranslation();
    const [radioValue, setRadioValue] = useState(undefined)

    return (
        <div>
            <Radio.Group onChange={(e) => setRadioValue(_.get(e, 'target.value'))} value={radioValue}>
                <Space direction="vertical">
                    <span className={"font-bold"}>
                        {t("newCalculation_copyValues_text")}
                    </span>
                    {
                        solutions.map(solution => {
                            return <Radio key={_.get(solution, 'calculation.id')} value={_.get(solution, 'calculation.id')}>{getSolutionTitle(solution, t)}</Radio>
                        })
                    }
                    <span className={"font-bold"}>
                        {t("newCalculation_blankValues_text")}
                    </span>
                    <Radio value={undefined}>{t("New calculation")}</Radio>
                </Space>
            </Radio.Group>
            <div className={"flex justify-flex-end mt-16"}>
                <Button
                    type={"primary"}
                    onClick={() => onAddCalculation(radioValue)}
                    icon={"add"}
                >
                    {t("Add calculation")}
                </Button>
            </div>
        </div>
    )
}

export default AddCollectionCalculationForm;
