import React, {useState} from 'react';
import {Col, Form, Radio, Row, Select, Space} from "antd";
import _, {round} from "lodash";
import NumberInput from "../NumberInput";
import {Unit} from "../../types";
import {getFormAttributes} from "./getFormAttributes";
import {useTranslation} from "react-i18next";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import "./index.css";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    onBackToHome: () => void;
    onChangeMathFormula: (formula: string) => void
}

const GasFlowPressureSuctionSpeedForm = ({onBackToHome, onChangeMathFormula}: Props) => {

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const attributes = getFormAttributes("gasFlowPressureSuctionSpeed", t)
    const [selectedCalculationValue, setSelectedCalculationValue] = useState(_.get(_.last(attributes), 'id'))
    const [calculationDone, setCalculationDone] = useState(false);

    const gasFlowFormula = String.raw`Q = p \cdot S`
    const pressureFormula = String.raw`p = \frac{Q}{S}`
    const suctionSpeedFormula = String.raw`S = \frac{Q}{p}`


    let initialValues = {}
    attributes.forEach((attribute) => {
        Object.assign(initialValues, {
            [attribute.id]: null,
            [`${attribute.id}_unit`]: attribute.units[0].id
        })
    })

    const onSubmitForm = (values: any) => {
        let gasFlow = convertUnitToSi(parseLocalizedFloat(_.get(values, 'gasFlow')), _.get(values, 'gasFlow_unit'));
        let pressure = convertUnitToSi(parseLocalizedFloat(_.get(values, 'pressure')), _.get(values, 'pressure_unit'));
        let suctionSpeed = convertUnitToSi(parseLocalizedFloat(_.get(values, 'suctionSpeed')), _.get(values, 'suctionSpeed_unit'));

        if (selectedCalculationValue === "gasFlow" && pressure && suctionSpeed) {
            gasFlow = convertUnitFromSi((pressure * suctionSpeed), _.get(values, 'gasFlow_unit'))

            form.setFieldsValue({
                [`gasFlow`]: roundNumber(gasFlow)
            })
        }

        if (selectedCalculationValue === "pressure" && gasFlow && suctionSpeed) {
            pressure = convertUnitFromSi((gasFlow / suctionSpeed), _.get(values, 'pressure_unit'))

            form.setFieldsValue({
                [`pressure`]: roundNumber(pressure)
            })
        }

        if (selectedCalculationValue === "suctionSpeed" && gasFlow && pressure) {
            suctionSpeed = convertUnitFromSi((gasFlow / pressure), _.get(values, 'suctionSpeed_unit'))

            form.setFieldsValue({
                [`suctionSpeed`]: roundNumber(suctionSpeed)
            })
        }

        if (gasFlow && pressure && suctionSpeed) {
            setCalculationDone(true);
        }
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"full-width"}
                form={form}
                initialValues={initialValues}
                onFinish={onSubmitForm}
            >
                <Radio.Group
                    value={selectedCalculationValue}
                    onChange={(e) => {
                        const value = _.get(e, 'target.value')
                        setSelectedCalculationValue(value)
                        form.setFieldsValue({
                            [value]: null
                        })
                        onChangeMathFormula(value === "pressure" ? pressureFormula : value === "suctionSpeed" ? suctionSpeedFormula : gasFlowFormula)
                    }}
                >
                    <Space className={"full-width form-border-top form-border-bottom"} direction={"vertical"} style={{paddingTop: 12, paddingBottom: 12}}>
                            {
                                attributes.map(attribute => {
                                    return <Row gutter={16} align={"middle"} key={attribute.id}>
                                        <Col xs={24} md={12}>
                                            <Radio value={attribute.id}>
                                                <span className={selectedCalculationValue === attribute.id ? "font-bold" : ""}>
                                                    {t(attribute.label)}:
                                                </span>
                                            </Radio>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                className={"gasFlow-custom-form-attribute"}
                                                name={attribute.id}
                                                label={" "}
                                                required={selectedCalculationValue !== attribute.id}
                                                rules={selectedCalculationValue === attribute.id ? [] : [{ required: true }]}
                                            >
                                                <NumberInput
                                                    className={selectedCalculationValue === attribute.id && calculationDone ? "success-border" : ""}
                                                    disabled={selectedCalculationValue === attribute.id}
                                                    exponentialbreakpoint={0.01}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Item
                                                className={"gasFlow-custom-form-attribute"}
                                                name={`${attribute.id}_unit`}
                                                label={" "}
                                                required={selectedCalculationValue !== attribute.id}
                                                rules={selectedCalculationValue === attribute.id ? [] : [{ required: true }]}
                                            >
                                                <Select className={"full-width"}>
                                                    {
                                                        attribute.units.map((unit: Unit) => {
                                                            return <Select.Option value={unit.id} key={unit.value}>
                                                                {unit.display}
                                                            </Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                })
                            }
                    </Space>
                </Radio.Group>
                <SimpleCalculationActionButtons
                    onBack={onBackToHome}
                    onReset={form.resetFields}
                    formSubmitOnCalculate={true}
                />
            </Form>
        </div>
    );
}

export default GasFlowPressureSuctionSpeedForm;
