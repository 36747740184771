import * as React from "react"
import {useTranslation} from "react-i18next";

export default function DesorptionRateSvgSmall(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {

    const {t} = useTranslation();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 789 136"
            width="100%"
            {...props}
        >
            <path fill="#F5F5F5" d="M0 0h789v136H0z" />
            <path fill="url(#a)" d="M8 16h199v24H8z" />
            <linearGradient
                id="a"
                x1={260}
                x2={8}
                y1={28}
                y2={28}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#E0E0E0" />
                <stop offset={0.641} stopColor="#E0E0E0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="74"
                y="32"
                style={{fontWeight: 'bold', fontSize: '12px'}}
            >
                {t('Baked System')}
            </text>
            <path fill="url(#b)" d="M214 16h252v24H214z" />
            <linearGradient
                id="b"
                x1={460}
                x2={360}
                y1={28}
                y2={28}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#E0E0E0" />
                <stop offset={0.641} stopColor="#E0E0E0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="340"
                y="33"
                style={{fontWeight: 'bold', fontSize: '12px'}}
            >
                {t('Typical UHV application')}
            </text>
            <path fill="url(#c)" d="M340 56h224v24H340z" />
            <linearGradient
                id="c"
                x1={550}
                x2={450}
                y1={68}
                y2={68}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#E0E0E0" />
                <stop offset={0.641} stopColor="#E0E0E0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="420"
                y="72"
                style={{fontWeight: 'bold', fontSize: '12px'}}
            >
                {t('Typical industry application')}
            </text>
            <path fill="url(#d)" d="M413 96h256v24H413z" />
            <linearGradient
                id="d"
                x1={650}
                x2={550}
                y1={108}
                y2={108}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" stopOpacity={0} />
                <stop offset={0.344} stopColor="#E0E0E0" />
                <stop offset={0.641} stopColor="#E0E0E0" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <text
                x="510"
                y="111"
                style={{fontWeight: 'bold', fontSize: '12px'}}
            >
                {t('High desorption elastomeres')}
            </text>
        </svg>
    )
}


