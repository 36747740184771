import {gql} from "@apollo/client";

export const CALCULATION_RESULT_CURVES = gql`
    query CalculationResultCurves($id: ID!, $solutionParams: SolutionParamsInput) {
        calculationCollection (calculationCollectionId: $id) {
            id
            link
            sheetUrl
            solutionList (params: $solutionParams) {
                total
                pumpingStationFilter
                solutions {
                    id
                    count
                    type
                    calculation {
                        id
                        bypassCalculation
                        values {
                            targetPressure {
                                unit
                                value
                            }
                            targetTime {
                                unit
                                value
                            }
                            ignoreTargetTime {
                                value
                            }
                            chamberSurface {
                                unit
                                value
                            }
                            chamberVolume {
                                unit
                                value
                            }
                            chamberVolumeGeometry {
                                value
                            }
                            chamberVolumeHeight {
                                unit
                                value
                            }
                            chamberVolumeLength {
                                unit
                                value
                            }
                            chamberVolumeWidth {
                                unit
                                value
                            }
                            chamberVolumeRadius {
                                unit
                                value
                            }
                            desorption {
                                desorptionRate {
                                    unit
                                    value
                                }
                                desorptionSurfaceName {
                                    value
                                }
                                desorptionSurfaceSize {
                                    unit
                                    value
                                }
                            }
                            leakRate {
                                unit
                                value
                            }
                            primaryPipeDefault {
                                value
                            }
                            primaryPipeLength {
                                value
                                unit
                            }
                            primaryPipeDiameter {
                                value
                                unit
                            }
                            primaryPipeElbowCount {
                                value
                            }
                            turboPipeDefault {
                                value
                            }
                            turboPipeLength {
                                value
                                unit
                            }
                            turboPipeDiameter {
                                unit
                                value
                            }
                            enforceSameCount {
                                value
                            }
                            pumpFrequency {
                                value
                            }
                            pumpGasType {
                                value
                            }
                            pumpingPrinciple {
                                value
                            }
                            backingPump {
                                value
                              }
                              backingPumpCount {
                                value
                              }
                              rootsPump {
                                value
                              }
                              rootsPumpCount {
                                value
                              }
                              turboPump {
                                value
                              }
                              turboPumpCount {
                                value
                              }
                              pumpingStation {
                                value
                              }
                              pumpingStationCount {
                                value
                              }
                              customPumpSystem {
                                value
                              }
                        }
                    }
                    components {
                        title
                        count
                        type
                        url
                        imageUrl
                        filters {
                            name
                            value
                        }
                        rootsPump {
                            title
                            count
                        }
                        backingPump {
                            title
                            count
                        }
                        turboPump {
                            title
                            count
                        }
                    }
                    curves {
                        label
                        curveType
                        data {
                            x
                            y
                        }
                    }
                }
            }
            calculations {
                id
                valid
                insert
                values {
                    targetPressure {
                        unit
                        value
                    }
                    targetTime {
                        unit
                        value
                    }
                    ignoreTargetTime {
                        value
                    }
                    chamberSurface {
                        unit
                        value
                    }
                    chamberVolume {
                        unit
                        value
                    }
                    chamberVolumeGeometry {
                        value
                    }
                    chamberVolumeHeight {
                        unit
                        value
                    }
                    chamberVolumeLength {
                        unit
                        value
                    }
                    chamberVolumeWidth {
                        unit
                        value
                    }
                    chamberVolumeRadius {
                        unit
                        value
                    }
                    desorption {
                        desorptionRate {
                            unit
                            value
                        }
                        desorptionSurfaceName {
                            value
                        }
                        desorptionSurfaceSize {
                            unit
                            value
                        }
                    }
                    leakRate {
                        unit
                        value
                    }
                    primaryPipeDefault {
                        value
                    }
                    primaryPipeLength {
                        value
                        unit
                    }
                    primaryPipeDiameter {
                        value
                        unit
                    }
                    primaryPipeElbowCount {
                        value
                    }
                    turboPipeDefault {
                        value
                    }
                    turboPipeLength {
                        value
                        unit
                    }
                    turboPipeDiameter {
                        unit
                        value
                    }
                    enforceSameCount {
                        value
                    }
                    pumpFrequency {
                        value
                    }
                    pumpGasType {
                        value
                    }
                    pumpingPrinciple {
                        value
                    }
                    backingPump {
                        value
                      }
                      backingPumpCount {
                        value
                      }
                      rootsPump {
                        value
                      }
                      rootsPumpCount {
                        value
                      }
                      turboPump {
                        value
                      }
                      turboPumpCount {
                        value
                      }
                      pumpingStation {
                        value
                      }
                      pumpingStationCount {
                        value
                      }
                      customPumpSystem {
                        value
                      }
                }
            }
        }
    }
`;
