import React, {useEffect, useState} from "react";
import {Input, Select, Row, Col, Form, Modal, Button, Grid} from "antd";
import {useTranslation} from "react-i18next";
import {CaretDownFilled, InfoCircleFilled, CheckOutlined} from "@ant-design/icons";
import {CarouselCard} from "@dreebit/pv-components";
import _ from "lodash";
import {IDesorptionFormData} from "../../utils/convertDesorptionRateForm";
import {Step} from "../../types";
import {getValidationStatus} from "../../utils/getValidationStatus";
import NumberInput from "../NumberInput";
import {useMutation} from "@apollo/client";
import {CREATE_SURFACE} from "../../graphql/mutations/createSurface";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";

const {Option} = Select;

export interface Props {
    index: number,
    surface: IDesorptionFormData,
    onSaveSurface: (values: any) => void,
    onDeleteSurface: (index: any) => void,
    onList: () => void,
    onValuesChange?: any,
    onChangeDesorptionUnit?: (value: string) => void
    step?: Step,
    fieldValidations: any[],
    currentUser?: any
}

export default function DesorptionForm ({index, surface, onValuesChange, onChangeDesorptionUnit, onDeleteSurface, onSaveSurface, onList, step, fieldValidations, currentUser}: Props) {
    const {t} = useTranslation();

    const [form] = Form.useForm()
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    let desorptionValue = _.get(surface, 'desorptionRate');
    const [saveState, setSaveState] =
        useState<"ready" | "loading" | "done" | "error" | "disabled">(currentUser ? "ready" : "disabled");

    const surfaceUnits = _.get(step?.attributes.filter((x) => x.id === "desorptionSurfaceSize")[0], 'units', [])
    const desorptionUnits = _.get(step?.attributes.filter((x) => x.id === "desorptionRate")[0], 'units', [])

    useEffect(() => {
        if(desorptionValue) desorptionValue = Number(desorptionValue);

        form.setFieldsValue({
            ...surface,
            desorptionRate: desorptionValue && desorptionValue.toExponential ? desorptionValue?.toExponential(0) : ''
        })
    }, [form, surface]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const setModalVisibility = () => {
        setIsModalVisible(!isModalVisible);
    };

    const [createSurface] = useMutation(CREATE_SURFACE)

    const _saveSurface = () => {
        setSaveState("loading");
        const formValues = form.getFieldsValue(true);

        createSurface({
            variables: {
                input: {
                    title: _.get(formValues, 'desorptionSurfaceName'),
                    desorptionRate: parseLocalizedFloat(_.get(formValues, 'desorptionRate')),
                    desorptionRateUnit: _.get(formValues, 'desorptionRateUnit')
                },
            },
            refetchQueries: ["SurfaceList"]
        }).then((res) => {
            setSaveState("done");
        }).catch((err) => {
            const errorObject=JSON.parse(JSON.stringify(err));
            const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

            if (!_.get(formValues, 'desorptionRate')) {
                toast.error(<ToastContent
                    status={"error"}
                    text={t('desorption_save_error')}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            } else {
                toast.error(<ToastContent
                    status={"error"}
                    headline={t('Error saving')}
                    text={`${errorMessage}`}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            }
            setSaveState("error");
        })
    }

    if (saveState === "error" || "done") {
        setTimeout(() => {
            setSaveState(currentUser ? "ready" : "disabled")
        }, 10000)
    }

    return <>
        <CarouselCard
            title={(index === 0) ? t('Chamber surface') : t('Additional surface')}
            deletable={!(index === 0)}
            onSaveCard={_saveSurface}
            onDeleteCard={() => onDeleteSurface(index)}
            onList={onList}
            listDisabled={!currentUser}
            saveState={saveState}
        >
            <Form
                form={form}
                layout={'vertical'}
                onValuesChange={onValuesChange}
                initialValues={surface}
            >
                <Form.Item name={'desorptionSurfaceName'}
                           label={t('Name')}
                >
                    <Input className={'carousel-item-input carousel-item-input-name'}/>
                </Form.Item>
                <Row className={'carousel-item-row'} gutter={8}>
                    <Col span={screens.lg ? 8 : 24}>
                        <Form.Item
                            name={'desorptionSurfaceSize'}
                            validateStatus={getValidationStatus(fieldValidations, 'desorptionSurfaceSize')}
                            required={true}
                            label={
                                <div className={'flex-row flex-space-between'}>
                                    {t('Size')}
                                    {index === 0 ?
                                        <div className={'pl-8'}>
                                            <InfoCircleFilled rev={"default"} onClick={setModalVisibility}/>
                                        </div>
                                        : null}
                                </div>
                            }
                        >
                            <NumberInput
                                disabled={index === 0}
                                addonAfter={<Form.Item
                                name={"desorptionSurfaceSizeUnit"}
                                noStyle
                            >
                                    <Select
                                        defaultValue={_.get(surfaceUnits[0], 'value', "")}
                                        disabled={index === 0}
                                        suffixIcon={<CaretDownFilled rev={"default"}/>}
                                        tabIndex={-1}
                                    >
                                        {surfaceUnits.map((unit, index) => {
                                            return <Option
                                                value={unit.value}
                                                key={index}
                                            >
                                                {unit.display}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={screens.lg ? 16 : 24}>
                        <Form.Item
                            name={'desorptionRate'}
                            validateStatus={getValidationStatus(fieldValidations, 'desorptionRate')}
                            label={t('Desorption rate')}
                            required={true}
                        >
                            <NumberInput
                                addonAfter={<Form.Item name={"desorptionRateUnit"} noStyle>
                                    <Select
                                        defaultValue={_.get(desorptionUnits[0], 'value', "")}
                                        suffixIcon={<CaretDownFilled rev={"default"} />}
                                        tabIndex={-1}
                                        onChange={(value) => onChangeDesorptionUnit ? onChangeDesorptionUnit(value) : null}
                                    >
                                        {desorptionUnits.map((unit, index) => {
                                            return <Option
                                                value={unit.value}
                                                key={index}
                                            >
                                                {unit.display}
                                            </Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </CarouselCard>
        <Modal visible={isModalVisible} onCancel={setModalVisibility} footer={null}>
            <h4 className={'font-bold'}>{t('calculator_size_modal_title')}</h4>
            <p>{t('calculator_size_modal_text')}</p>
            <Button
                type="primary"
                onClick={setModalVisibility}
            >
                Ok
                <CheckOutlined rev={"default"} />
            </Button>
        </Modal>
    </>
}
