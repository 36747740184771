import React, {useState} from 'react';
import {Col, Form, Row, Select} from "antd";
import _ from "lodash";
import NumberInput from "../NumberInput";
import {Unit} from "../../types";
import {parseLocalizedFloat} from "../../utils/parseLocalizedFloat";
import SimpleCalculationActionButtons from "../SimpleCalculationActionButtons";
import {unitConfig} from "../../config/unitConfig";
import {gasConfig} from "../../config/gasConfig-full";
import {useTranslation} from "react-i18next";
import {getGasConstant} from "../../utils/getGasConstant";
import {convertUnitFromSi, convertUnitToSi} from "../../utils/unitConverter";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {roundNumber} from "../../utils/roundNumber";

interface Props {
    onBackToHome: () => void,
}

interface ResultType {
    value: number | undefined,
    unit: string | undefined
}

const LeakRateConversionForm = ({onBackToHome}: Props) => {

    const [form] = Form.useForm();
    const {t} = useTranslation();

    const resultUnits = unitConfig.filter((unit: Unit) => unit.category === "leakRate");
    const [result, setResult] = useState<ResultType>({value: undefined, unit: resultUnits[0].id})
    const [calculationDone, setCalculationDone] = useState(false);

    const attributes = [
        {
            id: "massFlow",
            label: t("Mass flow"),
            type: "number",
            units: unitConfig.filter((unit: Unit) => unit.category === "massFlow"),
            width: {
                xs: 24,
                md: 12
            }
        },
        {
            id: "gasSpecies",
            label: t("Gas species"),
            type: "string",
            options: gasConfig,
            width: {
                xs: 24,
                md: 12
            }
        },
        {
            id: "temperature",
            label: t("Temperature"),
            type: "number",
            units: unitConfig.filter((unit: Unit) => unit.category === "temperature"),
            width: {
                xs: 24,
                md: 12
            },
            defaultValue: 293
        },
    ]

    let initialValues = {}

    attributes.forEach((attribute) => {
        if (attribute.units && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: attribute.defaultValue || null,
                [`${attribute.id}_unit`]: attribute.units[0].id
            })
        } else if (attribute.options && attribute.id) {
            Object.assign(initialValues, {
                [attribute.id]: attribute.options[0].id
            })
        }
    })

    const calculateResult = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();

                const qm = convertUnitToSi(parseLocalizedFloat(_.get(values, 'massFlow')), _.get(values, 'massFlow_unit'));
                const T = convertUnitToSi(parseLocalizedFloat(_.get(values, 'temperature')), _.get(values, 'temperature_unit'));
                const gas = _.get(values, 'gasSpecies')
                const M = _.get(gasConfig.find(g => g.id === gas), 'molarMass')
                const R = getGasConstant();

                if (result.unit && qm && T && M) {
                    let calculatedResult = qm * ((R*T) / M);
                    // convert result to wanted unit
                    calculatedResult = convertUnitFromSi(calculatedResult, result.unit);
                    calculatedResult = roundNumber(calculatedResult)
                    setResult({...result, value: calculatedResult})
                    setCalculationDone(true);
                } else {
                    toast.error(<ToastContent
                        status={"error"}
                        text={t("Molecular mass of the gas could not be determined")}
                    />, {
                        autoClose: 7000,
                        pauseOnHover: true
                    })
                }
            })
    }

    return (
        <div className={"full-width"}>
            <Form
                className={"form-border-bottom"}
                layout={"vertical"}
                form={form}
                initialValues={initialValues}
            >
                <Row gutter={16}>
                    {
                        attributes.map(attribute => {
                            return <Col
                                key={attribute.id}
                                xs={{span: attribute.width.xs}}
                                md={{span: attribute.width.md}}
                            >
                                {
                                    attribute.units && attribute.type === "number" ?
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}`} label={attribute.label} required={true} rules={[{ required: true }]}>
                                                    <NumberInput />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name={`${attribute.id}_unit`} label={" "} required={true}>
                                                    <Select tabIndex={-1}>
                                                        {attribute.units.map((unit: Unit) => {
                                                            return <Select.Option
                                                                value={unit.id}
                                                                key={unit.value}
                                                            >
                                                                {unit.display}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        : attribute.options ?
                                            <Form.Item name={`${attribute.id}`} label={attribute.label} required={true}>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) => {
                                                        if (option && option.children) {
                                                            // @ts-ignore
                                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        return false;
                                                    }}
                                                >
                                                    {
                                                        attribute.options.map((option: any, index: number) => {
                                                            return <Select.Option value={option.id} key={index}>
                                                                {t(option.label)}
                                                            </Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            : null
                                }
                            </Col>
                        })
                    }
                </Row>
            </Form>
            <div className={"result-row"}>
                <Row gutter={16} align={"middle"}>
                    <Col xs={24} md={12}>
                        <span className={"font-bold"}>{t("pV throughput (gas species)")}:</span>
                    </Col>
                    <Col xs={12} md={6}>
                        <NumberInput className={calculationDone ? "success-border" : ""} disabled value={result.value} exponentialbreakpoint={0.01}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Select
                            className={"full-width"}
                            value={result.unit}
                            onChange={(value => setResult({...result, unit: value}))}
                        >
                            {
                                resultUnits.map((unit: Unit) => {
                                    return <Select.Option value={unit.id} key={unit.value}>
                                        {unit.display}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
            </div>
            <SimpleCalculationActionButtons
                onBack={onBackToHome}
                onReset={() => {
                    setCalculationDone(false);
                    form.resetFields()
                    setResult({...result, value: undefined})
                }}
                onCalculate={() => calculateResult()}
            />
        </div>
    );
}

export default LeakRateConversionForm;
